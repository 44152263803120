import React from "react";
import facebookLogo from "../../images/facebook.png";
import twitterLogo from "../../images/twitter.png";
import linkedinLogo from "../../images/linkedin.png";
import redditLogo from "../../images/reddit.png";
import instagramLogo from "../../images/instagram.jpg";
import repLogo from "../../images/reputationaire-logo.png";
import { APP_NAME } from "../../utils/serviceName";

function Footer() {
  return (
    <footer>
      <div className="contact">
        <p>
          <a href="https://reputationaire.com/privacy" target="blank">
            PRIVACY POLICY
          </a>
        </p>
        <p>
          <a href="https://reputationaire.com/contact" target="blank">
            CONTACT
          </a>
        </p>
        <p>
          <a href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid" target="blank">
            FREE CHROME EXTENSION
          </a>
        </p>
        <p>
          <a href="mailto:techierank-api@reputationaire.com?subject=TechieRank API access&amp;body=I'm interested in API access to TechieRank rankings..." target="blank">
            API ACCESS
          </a>
        </p>
        <p>
          <a href="https://reputationaire.com/terms" target="blank">
            TERMS
          </a>
        </p>
        <p>© {APP_NAME}. All Rights Reserved.</p>
      </div>
      <div className="social">
        <p>
          <a href="https://www.facebook.com/TechieRank" target="blank">
            <img src={facebookLogo} alt="social-icon-first" />
          </a>
          <a href="https://twitter.com/TechieRank" target="blank">
            <img src={twitterLogo} alt="social-icon" />
          </a>
          <a href="https://www.instagram.com/techierank/" target="blank">
            <img src={instagramLogo} alt="social-icon" />
          </a>
          <a href="https://www.linkedin.com/company/techierank" target="blank">
            <img src={linkedinLogo} alt="social-icon" />
          </a>
          <a href="https://www.reddit.com/r/TechieRank/" target="blank">
            <img src={redditLogo} alt="social-icon" />
          </a>
        </p>
        <div className="repContainer">
          <p>Powered by</p>&nbsp;&nbsp;
          <a href="https://reputationaire.com/?techierank.com" target="blank">
            <img src={repLogo} alt="rep-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
