import React from 'react';

const LoadingAnimation = ({loadingText}) => {
    return (
        <div id="loader">
            <div id="lds-ripple">
                <div></div>
                <div></div>
            </div>
            <h2>{loadingText}</h2>
        </div>
    )
}

export default LoadingAnimation;