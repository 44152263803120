/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type DrawerProps = {
  isCredit: string;
  credit: number;
  showPayment: any;
  goToRegister: any;
  closeDrawer: any;
  logout: any;
};
const Drawer = ({
  isCredit,
  credit,
  showPayment,
  goToRegister,
  closeDrawer,
  logout,
}: DrawerProps) => {
  return (
    <div className="drawer">
      <button onClick={() => closeDrawer()}>x</button>
      {isCredit ? (
        <h4>
          <span onClick={showPayment} className="moreCreditsBtn">
            Buy Credits
          </span>{" "}
          <span className="divider">|</span>
          <span className={`creditLeft ${credit === 0 ? "no-credit" : ""}`}>
            {credit} credits
          </span>
          <span className="divider">|</span>
          <span className="logout" onClick={logout} >Logout</span>
        </h4>
      ) : (
        <a
          //href="#"
          onClick={() => goToRegister()}
          target="_blank"
          rel="noopener noreferrer"
        >
          Check 5 Developer's Tech &amp; Soft Skills Free
        </a>
      )}
      <a
        href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid"
        //onClick={() => alert("Coming soon!")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Free Chrome Extension
      </a>

      <a
        href="mailto:techierank-api@reputationaire.com?subject=TechieRank API access&amp;body=I'm interested in API access to TechieRank rankings..."
        //onClick={() => alert("Coming soon!")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Get API Access
      </a>
      
    </div>
  );
};

export default Drawer;
