import React, { useState, useRef } from 'react';
import axios from 'axios';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
import { APP_NAME } from '../../utils/serviceName';
import Nav from '../../components/RequestRating/Nav';
import Footer from '../../components/RequestRating/Footer';
import errorIcon from '../../images/error.png';
import successIcon from '../../images/checked.png';
import loadingIcon from '../../images/sand-clock.png';

const RegisterMain = () => {
	const history = useHistory();
	const [email, setEmail] = useState('');
	const [clientName, setClientName] = useState('');
	const [company, setCompany] = useState('');
	const emailRef = useRef(null);
	const nameRef = useRef(null);
	const companyRef = useRef(null);
	const emailErrorRef = useRef(null);
	const nameErrorRef = useRef(null);
	const companyErrorRef = useRef(null);
	const errorBoxRef = useRef(null);
	const successBoxRef = useRef(null);
	const loadingBoxRef = useRef(null);

	const validateEmail = (address) => {
		let allowSeeding =
			process.env.REACT_APP_TEST_MODE_ALLOW_SIGNUP_WITH_SEEDED_EMAILS;
		let seedCondition = true;
		if (!allowSeeding) {
			seedCondition = address.indexOf('+') === -1;
		}
		const isInputEmail = validator.isEmail(address) && seedCondition;
		const isValidAddress = address.match(/^X[a-zA-Z0-9]{33}$/) !== null;
		return isInputEmail || isValidAddress;
	};

	const isFormValid = () => {
		let isValid = true;
		const EMPTY_EMAIL_STRING = 'Please enter your email';
		const EMPTY_NAME_STRING = 'Please enter your name';
		const INVALID_NAME_STRING =
			'Please enter your name with no special characters or numbers';
		const INVALID_EMAIL_STRING =
			'Please enter your email in the correct format (you@domain.com)';
		if (email === '') {
			if (window.innerHeight > 700) {
				emailRef.current.classList.add('error');
				emailErrorRef.current.classList.add('showError');
				emailErrorRef.current.innerHTML = EMPTY_EMAIL_STRING;
			} else {
				errorBoxRef.current.parentNode.classList.add('showErrorBox');
				if (errorBoxRef.current.innerHTML !== '') {
					errorBoxRef.current.innerHTML += '<br/>';
				}
				errorBoxRef.current.innerHTML += `• ${EMPTY_EMAIL_STRING}`;
				emailRef.current.classList.add('error');
			}

			isValid = false;
		}

		if (clientName === '') {
			if (window.innerHeight > 700) {
				nameRef.current.classList.add('error');
				nameErrorRef.current.classList.add('showError');
				nameErrorRef.current.innerHTML = EMPTY_NAME_STRING;
			} else {
				errorBoxRef.current.parentNode.classList.add('showErrorBox');
				if (errorBoxRef.current.innerHTML !== '') {
					errorBoxRef.current.innerHTML += '<br/>';
				}
				errorBoxRef.current.innerHTML += `• ${EMPTY_NAME_STRING}`;
				nameRef.current.classList.add('error');
			}

			isValid = false;
		}

		if (clientName !== '' && !RegExp(/^['a-zA-Z-( )]+$/gm).test(clientName)) {
			if (window.innerHeight > 700) {
				nameRef.current.classList.add('error');
				nameErrorRef.current.classList.add('showError');
				nameErrorRef.current.innerHTML = INVALID_NAME_STRING;
			} else {
				errorBoxRef.current.parentNode.classList.add('showErrorBox');
				if (errorBoxRef.current.innerHTML !== '') {
					errorBoxRef.current.innerHTML += '<br/>';
				}
				errorBoxRef.current.innerHTML += `• ${INVALID_NAME_STRING}`;
				nameRef.current.classList.add('error');
			}

			isValid = false;
		}

		if (email !== '' && !validateEmail(email)) {
			if (window.innerHeight > 700) {
				emailRef.current.classList.add('error');
				emailErrorRef.current.classList.add('showError');
				emailErrorRef.current.innerHTML = INVALID_EMAIL_STRING;
			} else {
				errorBoxRef.current.parentNode.classList.add('showErrorBox');
				if (errorBoxRef.current.innerHTML !== '') {
					errorBoxRef.current.innerHTML += '<br/>';
				}
				errorBoxRef.current.innerHTML += `• ${INVALID_EMAIL_STRING}`;
				emailRef.current.classList.add('error');
			}

			isValid = false;
		}

		return isValid;
	};

	const emptyForm = () => {
		setEmail('');
		setClientName('');
		setCompany('');
	};

	const clearError = () => {
		errorBoxRef.current.parentNode.classList.remove('showErrorBox');
		nameRef.current.classList.remove('error');
		emailRef.current.classList.remove('error');
		companyRef.current.classList.remove('error');
		nameErrorRef.current.classList.remove('showError');
		emailErrorRef.current.classList.remove('showError');
		companyErrorRef.current.classList.remove('showError');
		errorBoxRef.current.innerHTML = '';
	};

	const clearSuccess = () => {
		successBoxRef.current.parentNode.classList.remove('showSuccessBox');
		history.push('/');
	};

	const showSuccess = (name, email) => {
		successBoxRef.current.innerHTML = `Thank you for your interest ${name}. Your personal ${APP_NAME} access link has been sent to ${email}. 
        Just click it to access your 5 free checks.`;
		successBoxRef.current.parentNode.classList.add('showSuccessBox');
	};

	const showLoading = () => {
		loadingBoxRef.current.classList.add('showLoadingBox');
	};

	const stopLoading = () => {
		loadingBoxRef.current.classList.remove('showLoadingBox');
	};

	const onSubmitClicked = (e) => {
		e.preventDefault();
		clearError();
		if (!isFormValid()) {
			return;
		}

		showLoading();

		let data = {
			email: email,
			clientName: clientName,
			company: company,
		};

		let config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		axios
			.post(
				`${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/api/register`,
				data,
				config
			)
			.then((res) => {
				stopLoading();
				showSuccess(clientName, email);
				emptyForm();
				clearError();
			})
			.catch((err) => {
				stopLoading();
				errorBoxRef.current.parentNode.classList.add('showErrorBox');
				errorBoxRef.current.innerHTML =
					"We're having trouble dealing with your request. Please try again or contact customer support.";
			});
	};

	return (
		<div className='wrapper registerContainer'>
			<Nav />
			<form onSubmit={onSubmitClicked} className='registerForm'>
				<div className='createAccount'>
					<p>You're one step away from spotting your diamond in the rough applicants</p>
					<div className='inputContainer'>
						<input
							ref={nameRef}
							type='text'
							placeholder='Enter Name'
							name='name'
							value={clientName}
							onChange={(e) => {
								setClientName(e.target.value);
							}}
						/>
						<p ref={nameErrorRef} className='errorContainer'></p>
					</div>
					<div className='inputContainer'>
						<input
							ref={emailRef}
							type='email'
							placeholder='Enter Email'
							name='email'
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
						<p ref={emailErrorRef} className='errorContainer'></p>
					</div>
					<div className='inputContainer'>
						<input
							ref={companyRef}
							type='text'
							placeholder='Company (optional)'
							name='company'
							value={company}
							onChange={(e) => {
								setCompany(e.target.value);
							}}
						/>
						<p ref={companyErrorRef} className='errorContainer'></p>
					</div>
					<span className='consent'>
						<h4>By creating an account, you agree to the </h4>
						<a
							href='https://reputationaire.com/terms'
							rel='noopener noreferrer'
							target='_blank'
						>
							<h4> Terms of Use</h4>
						</a>
						<h4> and </h4>
						<a
							href='https://reputationaire.com/privacy'
							rel='noopener noreferrer'
							target='_blank'
						>
							<h4> Privacy Policy</h4>
						</a>
					</span>
					<button type='submit'>Check 5 Developer's Tech &amp; Soft Skills Free</button>
				</div>
			</form>
			<div ref={loadingBoxRef} className='loadingBox'>
				<img src={loadingIcon} alt='loading' />
				<p>Please wait</p>
			</div>
			<div className='errorBox'>
				<img src={errorIcon} alt='error' />
				<p ref={errorBoxRef}></p>
				<p>
					<a href='https://reputationaire.com/contact' target='blank'>
						Click here to contact support
					</a>
				</p>
				<button onClick={clearError} type='button'>
					OK
				</button>
			</div>
			<div className='successBox'>
				<img src={successIcon} alt='success' />
				<p ref={successBoxRef}></p>
				<button onClick={clearSuccess} type='button'>
					OK
				</button>
			</div>
			<Footer />
		</div>
	);
};

export default RegisterMain;
