/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { GITHUB_STRING, SO_STRING } from '../../../utils/serviceName';

const Callback = ({ location }) => {
	const GH_TOKEN_STRING = 'access_token';
	const SO_TOKEN_STRING = 'access_token_stack';
	const CODE_STRING = 'code';
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		let params = {};
		for (let param of query.entries()) {
			params[param[0]] = param[1];
		}
		if (params.hasOwnProperty(GH_TOKEN_STRING)) {
			window.localStorage.setItem(
				CODE_STRING,
				JSON.stringify({
					type: GITHUB_STRING,
					token: params[GH_TOKEN_STRING],
				})
			);
		}

		if (params.hasOwnProperty(SO_TOKEN_STRING)) {
			window.localStorage.setItem(
				CODE_STRING,
				JSON.stringify({
					type: SO_STRING,
					token: params[SO_TOKEN_STRING],
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ fontSize: 32, color: 'white' }}>
			Please wait... (or if this window doesn't disappear please close it and try
			again)
		</div>
	);
};

export default Callback;
