import React, { useState, useEffect } from 'react';
import { GITHUB_STRING, APP_NAME, DISPLAY_STRING_GH, DISPLAY_STRING_SO } from '../../../utils/serviceName';
import logo from '../../../images/logo.png';

const Prompt = ({ location }) => {
	const [remindMe /*, setRemindMe */ ] = useState(false);
	const [type, setType] = useState('');
	const [title, setTitle] = useState('');
	const githubUrl = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_ID}&redirect_uri=${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/github-redirect`;
	const stackUrl = `https://stackoverflow.com/oauth/?client_id=${process.env.REACT_APP_STACKOF_ID}&redirect_uri=${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/stackoverflow-redirect`;
	const enablePrivateContributionArticle =
		'https://docs.github.com/en/github/setting-up-and-managing-your-github-profile/publicizing-or-hiding-your-private-contributions-on-your-profile';

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		let params = {};
		for (let param of query.entries()) {
			params[param[0]] = param[1];
		}
		if (params.hasOwnProperty('type')) {
			setType(params['type']);
			setTitle(params['type'] === GITHUB_STRING ? DISPLAY_STRING_GH : DISPLAY_STRING_SO);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const checkRemindMe = () => {
		if (remindMe) {
			window.localStorage.setItem(`remindMe-${type}`, 'true');
		}
	};

	const renderSOData = () => (
		<>
			<li className='center'>Your reputation score</li>
		</>
	);

	const renderGitHubData = () => {
		return (
			<>
				<li>The highest starred repository you have contributed to</li>
				<li>The number of your followers</li>
				<li>
					Number of repositories you have contributed to:
					<p>
						<br />
						- We only analyse contributions made to public repos
						<br />
						- <font color="#4cde67"><b>To increase your rank you can include your activity on private repos without making them public</b></font>
						<br />-{' '}
						<a
							href={enablePrivateContributionArticle}
							target='_blank'
							rel='noopener noreferrer'
						>
							Follow these instructions to make your private activity visible
						</a>
						<br />- Note: This does NOT make your private repos public, just shows your activity on them
					</p>
				</li>

			{/* 2021-12-10 tried A/B test hide Act on Your Behalf and checked session duration but with got longer sessions https://optimize.google.com/optimize/home/#/accounts/3623840650/containers/9427895/experiments/10/report?utm_source=opt_notifications&utm_medium=email&utm_campaign=opt_exp_notifications&utm_content=opt_exp_ended */}
				<li><font color="#4cde67"><b>
					We request only your {DISPLAY_STRING_GH} username - we do NOT "Act On Your Behalf"
					</b>
					</font><p><br/>- See <a
							href='https://github.community/t/enable-you-to-trigger-actions-in-github/117938/9'
							target='_blank'
							rel='noopener noreferrer'
						>
							here
						</a> for {DISPLAY_STRING_GH}'s information on their strangely worded "Act On My Behalf" permission
					</p>
				</li>
			</>
		);
	};

	return (
		<div className='wrapper promptContainer'>
			<img className='logo' src={logo} alt={`${APP_NAME}-logo`} />
			<div className='data-desc'>
				<h2>
					In order to calculate your rank, we need you prove you own your {title} username, then we'll look at this public information:
				</h2>
				<div className='dataContainer'>
					<ul>
						{type === GITHUB_STRING ? renderGitHubData() : renderSOData()}
					</ul>
				</div>
			</div>
			<div className='actionContainer'>
				<div className='buttons'>
					<button onClick={() => window.close()}>Cancel</button>
					<a
						onClick={checkRemindMe}
						href={type === GITHUB_STRING ? githubUrl : stackUrl}
					>
						Continue
					</a>
				</div>

				{/* 2021-06-25 commented out as people auto click without reading then can't see info again on GH "Act on my behalf" <div className='remindMe'>
					<input
						onChange={(e) => setRemindMe(e.target.checked)}
						id='remind-me'
						type='checkbox'
						value='true'
					/>
					<label htmlFor='remind-me'> Do not remind me of this again</label>
				</div> */}
			</div>
		</div>
	);
};

export default Prompt;
