import React, { useState, useRef } from "react";
import axios from "axios";
import errorIcon from "../../images/error.png";
import loadingIcon from "../../images/sand-clock.png";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const nameRef = useRef(null);
  const nameErrorRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordErrorRef = useRef(null);
  const errorBoxRef = useRef(null);
  const loadingBoxRef = useRef(null);

  const isFormValid = () => {
    let isValid = true;
    const EMPTY_NAME_STRING = "Please enter your username";
    const INVALID_PASSWORD_STRING = "Please enter your password";

    if (username === "") {
      if (window.innerHeight > 700) {
        nameRef.current.classList.add("error");
        nameErrorRef.current.classList.add("showError");
        nameErrorRef.current.innerHTML = EMPTY_NAME_STRING;
      } else {
        errorBoxRef.current.parentNode.classList.add("showErrorBox");
        if (errorBoxRef.current.innerHTML !== "") {
          errorBoxRef.current.innerHTML += "<br/>";
        }
        errorBoxRef.current.innerHTML += `• ${EMPTY_NAME_STRING}`;
        nameRef.current.classList.add("error");
      }

      isValid = false;
    }

    if (password === "") {
      if (window.innerHeight > 700) {
        passwordRef.current.classList.add("error");
        passwordErrorRef.current.classList.add("showError");
        passwordErrorRef.current.innerHTML = INVALID_PASSWORD_STRING;
      } else {
        errorBoxRef.current.parentNode.classList.add("showErrorBox");
        if (errorBoxRef.current.innerHTML !== "") {
          errorBoxRef.current.innerHTML += "<br/>";
        }
        errorBoxRef.current.innerHTML += `• ${INVALID_PASSWORD_STRING}`;
        nameRef.current.classList.add("error");
      }

      isValid = false;
    }

    return isValid;
  };

  const emptyForm = () => {
    setUsername("");
    setPassword("");
  };

  const clearError = () => {
    errorBoxRef.current.parentNode.classList.remove("showErrorBox");
    nameRef.current.classList.remove("error");
    passwordRef.current.classList.remove("error");
    nameErrorRef.current.classList.remove("showError");
    passwordErrorRef.current.classList.remove("showError");
    errorBoxRef.current.innerHTML = "";
  };

  const showLoading = () => {
    loadingBoxRef.current.classList.add("showLoadingBox");
  };

  const stopLoading = () => {
    loadingBoxRef.current.classList.remove("showLoadingBox");
  };

  const handleLogin = (e) => {
    e.preventDefault();
    clearError();
    if (!isFormValid()) {
      return;
    }
    showLoading();

    const urlLogin = `${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/api/login`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const data = {
      username: username,
      password: password,
    };

    axios
      .post(urlLogin, data, config)
      .then((res) => {
        stopLoading();
        emptyForm();
        let adminAuth = res.data.message.auth;
        props.setAdminAuth(adminAuth);
        props.history.push("/admin");
      })
      .catch((err) => {
        console.log(err);
        stopLoading();
        errorBoxRef.current.parentNode.classList.add("showErrorBox");
        errorBoxRef.current.innerHTML = err.message;
      });
  };

  return (
    <div className="wrapper registerContainer">
      <div />
      <form onSubmit={handleLogin} className="registerForm">
        <div className="createAccount">
          <p>Admin Login</p>
          <div className="inputContainer">
            <input
              ref={nameRef}
              type="text"
              placeholder="Enter Username"
              name="name"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <p ref={nameErrorRef} className="errorContainer"></p>
          </div>
          <div className="inputContainer">
            <input
              ref={passwordRef}
              type="password"
              placeholder="Enter Password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <p ref={passwordErrorRef} className="errorContainer"></p>
          </div>
          <button type="submit">Login</button>
        </div>
      </form>
      <div ref={loadingBoxRef} className="loadingBox">
        <img src={loadingIcon} alt="loading" />
        <p>Please wait</p>
      </div>
      <div className="errorBox">
        <img src={errorIcon} alt="error" />
        <p ref={errorBoxRef}></p>
        <button onClick={clearError} type="button">
          OK
        </button>
      </div>
      <div />
    </div>
  );
};

export default Login;
