import React from 'react';
import { APP_NAME } from '../../utils/serviceName';

const PaymentsModal = ({hidePayment}) => {
  return (
    <section id="getMoreCredits">
      <div className="moreCreditContainer">
        <div className="closeContainer">
          <button onClick={hidePayment}>x</button>
        </div>
        <div className="getMoreCredits">
          <h2>Purchase credits to check more users' rankings</h2>
          <p>
            For help, contact us at <a href="mailto:">{APP_NAME}@reputationaire.com</a>
          </p>
          <p>
            Launch Promo! 75% off all bundles!
          </p>

          <hr />

          <form
            className="buyCredits"
            action="https://www.paypal.com/cgi-bin/webscr"
            method="post"
            // target="_top"
            target="_blank"
          >
            <input type="hidden" name="cmd" value="_xclick" />
            <input type="hidden" name="business" value="paypal@breezily.com" />
            <input type="hidden" name="lc" value="AU" />
            <input type="hidden" name="item_name" value="10 credits" />
            <input type="hidden" name="item_number" value="10" />
            <input type="hidden" name="button_subtype" value="services" />
            <input type="hidden" name="no_note" value="0" />
            <input type="hidden" name="shipping" value="0.00" />
            <input
              type="hidden"
              name="bn"
              value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHostedGuest"
            />
            <select name="os0">
              <option value="5 credits">5 credits $3.00 AUD</option>
              <option value="10 credits">10 credits $5.00 AUD</option>
              <option value="20 credits">20 credits $9.00 AUD</option>
              <option value="50 credits">50 credits $20.00 AUD</option>
              <option value="100 credits">100 credits $35.00 AUD</option>
            </select>
            <input type="hidden" name="currency_code" value="AUD" />
            <input type="hidden" name="option_select0" value="5 credits" />
            <input type="hidden" name="option_amount0" value="3.00" />
            <input type="hidden" name="option_select1" value="10 credits" />
            <input type="hidden" name="option_amount1" value="5.00" />
            <input type="hidden" name="option_select2" value="20 credits" />
            <input type="hidden" name="option_amount2" value="9.00" />
            <input type="hidden" name="option_select3" value="50 credits" />
            <input type="hidden" name="option_amount3" value="20.00" />
            <input type="hidden" name="option_select4" value="100 credits" />
            <input type="hidden" name="option_amount4" value="35.00" />
            <input type="hidden" name="option_index" value="0" />
            <input
              type="image"
              src="/paypal.png"
              border="0"
              name="submit"
              alt="PayPal – The safer, easier way to pay online!"
            />
            <img
              alt=""
              border="0"
              src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif"
              width="1"
              height="1"
            />
          </form>
        </div>
      </div>
    </section>
  );
}



export default PaymentsModal;