import React from 'react'
import Intern from "../images/intern.png"
import Junior from "../images/junior.png"
import MidLevel from "../images/mid-level.png"
import Senior from "../images/senior.png"

const Level_dialog = ({setExpectedLevelGH, setExpectedLevelSO, github_search, setOpenDialog}) => {

  const customComp = (setOpenDialog, funcs) => {

    return <div className="what-level_content">
      <div className="what-level-header-wraper">
        <h3>
          What level do you want to hire?
        </h3>
      </div>
      <div className="iconContainer">
        <div onClick={() => {
          funcs.forEach(element => {
            element('intern')
          });
          setOpenDialog(false)
          }}>
          <img src={Intern} alt="intern-icon" />
          <p>Intern<br/>Less than 1 year experience</p>
        </div>
        <div onClick={() => {
          funcs.forEach(element => {
            element('junior')
          });
          setOpenDialog(false)
          }}>
          <img src={Junior} alt="junior-icon" />
          <p>Junior<br/>1-2 years experience</p>
        </div>
        <div onClick={() => {
          funcs.forEach(element => {
            element('mid-level')
          });
          setOpenDialog(false)
          }}>
          <img src={MidLevel} alt="mid-icon" />
          <p>Mid level<br/>2-5 years experience</p>
        </div>
        <div onClick={() => {
          funcs.forEach(element => {
            element('senior')
          });
          setOpenDialog(false)
          }}>
          <img src={Senior} alt="mid-icon" />
          <p>Senior<br/>5+ years experience</p>
        </div>
      </div>
    </div>
  }

    return (
        <div className="what-level">
          {
            github_search === true ? 
              customComp(setOpenDialog, [setExpectedLevelGH])
              :
              github_search === false ? 
              customComp(setOpenDialog, [setExpectedLevelSO])
              :
              customComp(setOpenDialog, [setExpectedLevelSO, setExpectedLevelGH])
          }
          
      </div>
    )
}

export default Level_dialog
