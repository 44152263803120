import React, { useState } from "react";
import { Route } from "react-router-dom";
import RequestRating from "./RequestRating/RequestRating";
import AdminMain from "./Admin/AdminMain";
import RegisterMain from "./Register/RegisterMain";
import Login from "./Admin/Login";
import Callback from "../components/RequestRating/OAuth/Callback";
import Prompt from "../components/RequestRating/OAuth/Prompt";

const MainContainer = () => {
  const [adminAuth, setAdminAuth] = useState("");

  return (
    <React.Fragment>
      <Route path="/" exact render={(props) => <RequestRating {...props} />} />
      <Route exact path="/oauth_callback" component={Callback} />
      <Route exact path="/prompt" component={Prompt} />
      <Route
        path="/admin"
        exact
        render={(props) => <AdminMain {...props} adminAuth={adminAuth} />}
      />
      <Route
        path="/login"
        exact
        render={(props) => <Login {...props} setAdminAuth={setAdminAuth} />}
      />
      <Route path="/register" exact component={RegisterMain} />
    </React.Fragment>
  );
};

export default MainContainer;
