
export const createPopup = (width, height, type, externalWindow, onCode, CODE_STRING, STORAGE_STRING, url, title) => {
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`;

    const checkRemindMe = window.localStorage.getItem(`remindMe-${type}`);

    externalWindow = window.open(
        checkRemindMe
            ? url
            : `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}prompt?type=${type}`,
        title,
        windowFeatures
    );

    const storageListener = () => {
        try {
            if (localStorage.getItem(CODE_STRING)) {
                onCode(JSON.parse(localStorage.getItem(CODE_STRING)));
                externalWindow.close();
                window.removeEventListener(STORAGE_STRING, storageListener);
            }
        } catch (e) {
            window.removeEventListener(STORAGE_STRING, storageListener);
        }
    };

    window.addEventListener(STORAGE_STRING, storageListener);

    externalWindow.addEventListener(
        'beforeunload',
        () => {
            localStorage.removeItem(CODE_STRING);
        },
        false
    );
    // remove the code(type and token) from localstorage so that in future use the dialog would pop up successful
    localStorage.removeItem(CODE_STRING);
};