import React from 'react'

const OtherRankingRegister = ({navigateToRegister, show_a_Tag, show_btn_Tag}) => {
    return (
        <div className="other-ranking-register">
            <span>
                
                {show_btn_Tag === true && <button
                    className="btn-authorize"
                    onClick={navigateToRegister}
                    >
                    Check 5 Developer's Tech &amp; Soft Skills Free
                    </button>
                }
                {
                    show_a_Tag === true && <a
                    className="btn-authorize"
                    href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid"
                    //onClick={() => alert("Coming soon!")}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    Get Free Chrome Extension
                    </a>
                }
                
            {/* 
                <a
                className="btn-authorize"
                href="mailto:techierank-api@reputationaire.com?subject=TechieRank API access&amp;body=I'm interested in API access to TechieRank rankings..."
                //onClick={() => alert("Coming soon!")}
                target="_blank"
                rel="noopener noreferrer"
                >
                Get API Access
                </a> */}

            </span>

        </div>
    )
}

export default OtherRankingRegister
