import React, { useEffect, useState } from "react";
import _ from "lodash";
import copyIcon from "../../images/copy.png";
// import infoIcon from "../../images/infoIcon.svg";
import {
  APP_NAME,
  DISPLAY_STRING_GH,
  DISPLAY_STRING_SO,
} from "../../utils/serviceName";
import BtnLinkedIn from "./BtnLinkedIn";
import LoginFromSharePopUp from "./LoginFromSharePopUp";
// import TwitterIcon from '../../images/twitter.png'

interface ShareProps {
  hash: string;
  twitter: () => void;
  linkedin: () => void;
  gitrank: string;
  sorank: string;
  hideShare: () => void;
  onCode: () => void;
}

const Share = ({ hash, twitter, linkedin, gitrank, sorank, hideShare, onCode}: ShareProps): JSX.Element => {
  const SHARE_TITLE = `your rankings & prove ownership of your profiles`;
  const [type, setType] = useState({
    string: "",
    both: true,
  });
  useEffect(() => {
    if (hash) {
      const profileURL = hash.slice(1);
      const profileProps = profileURL.split("/");

      if (profileProps.length < 2) {
        setType({
          string: "",
          both: true,
        });
      } else {
        if (profileProps.length === 3) {
          const [github, so] = profileProps;
          if (!_.isInteger(Number(github)) && _.isInteger(Number(so))) {
            setType({
              string: `${DISPLAY_STRING_GH} and ${DISPLAY_STRING_SO}`,
              both: true,
            });
          } else {
            setType({
              string: "",
              both: true,
            });
          }
        } else {
          const [profile] = profileProps;
          if (_.isInteger(Number(profile))) {
            setType({
              string: DISPLAY_STRING_SO,
              both: false,
            });
          } else if (!_.isInteger(Number(profile))) {
            setType({
              string: DISPLAY_STRING_GH,
              both: false,
            });
          } else {
            setType({
              string: "",
              both: true,
            });
          }
        }
      }
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  // const certName = type.string.replace(/ /g, '%20')
  const hashbreak = hash.split("/");
  const d = new Date();

  let liShareUrl = 'https://www.linkedin.com/';
  let liShareUrlString1 = 'https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=';
  let liShareUrlString2 = `&organizationId=72322324&issueYear=${d.getFullYear()}&issueMonth=${d.getMonth()+1}&certId=`;
  let liShareUrlString3 = '&certUrl=https%3A%2F%2Fwww.techierank.com%2F%23'
  let liShareUrlDate = `${d.getFullYear()}%2D${d.getMonth().toString().split("")[1] ? `${d.getMonth()+1}` : `${d.getMonth() < 9 ? 0 : ''}${d.getMonth()+1}`}%2D${d.getDate().toString().split("")[1] ? `${d.getDate()}` : `0${d.getDate()}`}`;
  let liShareUrlVerAndRanked = '%20Profile%20Ownership%20Verified%20%28Ranked%20in%20top%20';
  let liShareUrlVer = '%20Profile%20Ownership%20Verified%20as%20of%20';
  
  if(hashbreak.length === 2){
    if(sorank === 'not_top_50'){
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVer}${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[0].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}`;
    } else if(sorank && Number(hashbreak[0].slice(1))){
      // Stack Overflow ID IS is a string which can be converted to a number
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVerAndRanked}${sorank}%25%29%20as%20of%20${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[0].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}`;
    }

    if(gitrank === 'not_top_50'){
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_GH}${liShareUrlVer}${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}`;
    } else if(gitrank && !Number(hashbreak[0].slice(1))){
      // GitHub username is never a number
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_GH}${liShareUrlVerAndRanked}${gitrank}%25%29%20as%20of%20${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}`;
    }

  } else {
    if(sorank === 'not_top_50' && gitrank !== 'not_top_50'){
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_GH}${liShareUrlVerAndRanked}${gitrank}%25%29%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVer}${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[1].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}%2F${hashbreak[2]}`;
    } else if(gitrank === 'not_top_50' && sorank !== 'not_top_50'){
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVerAndRanked}${sorank}%25%29%20%7C%20${DISPLAY_STRING_GH}${liShareUrlVer}${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[1].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}%2F${hashbreak[2]}`;
    } else if(gitrank === 'not_top_50' && sorank === 'not_top_50'){
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_GH}%20Profile%20Ownership%20Verified%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVer}${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[1].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}%2F${hashbreak[2]}`;
    } else {
      liShareUrl = `${liShareUrlString1}${DISPLAY_STRING_GH}${liShareUrlVerAndRanked}${gitrank}%25%29%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}${liShareUrlVerAndRanked}${sorank}%25%29%20as%20of%20${liShareUrlDate}${liShareUrlString2}${DISPLAY_STRING_GH}%20Username%3A%20${hashbreak[0].slice(1)}%20%7C%20${DISPLAY_STRING_SO.split(" ")[0]}%20${DISPLAY_STRING_SO.split(" ")[1]}%20ID%3A%20${hashbreak[1].slice(1)}${liShareUrlString3}${hashbreak[0].slice(1)}%2F${hashbreak[1]}%2F${hashbreak[2]}`;
    }
    
  }

  const LIBtnonclickFunc = (e: any, liShareUrl: any) => {
    e.preventDefault();
    window.open(liShareUrl, "_blank");
  }
  const isGithubRankNotInTop50 = type.both === false && type.string === "GitHub" && (parseFloat(gitrank) > 50 || isNaN(parseFloat(gitrank)))
  const isSlackRankNotInTop50 = type.both === false && type.string === "Stack Overflow" && (parseFloat(sorank) > 50 || isNaN(parseFloat(sorank)))

  return (
    <div className="shareContainer">
      <div className="innerContainer">
        <div className="closeContainer">
          <button onClick={hideShare}>x</button>
        </div>
        <div className="contentContainer">
          <span>

            <div className="titleContainer">
              {!type.both ? (
                <>
                    <h2>
                      Tip: first <React.StrictMode><u><LoginFromSharePopUp onCode={onCode} type={type} DISPLAY_STRING_GH={DISPLAY_STRING_GH} DISPLAY_STRING_SO={DISPLAY_STRING_SO} /></u></React.StrictMode> to share both
                    </h2><br/>
                    </>
                  ) : null}
            </div>
            
            
            <div className="socialMediaContainer">
              <span>
                {/* <button className="btn-twitter" onClick={twitter}>
                  <img src={TwitterIcon} alt="Twitter icon" />
                  Share on Twitter
                </button> */}
                <React.StrictMode>
                  <BtnLinkedIn LIBtnonclickFunc={LIBtnonclickFunc} classname='btn-linkedIn' liShareUrl={liShareUrl} />
                </React.StrictMode>
              </span>
            </div>
            <div className="more_info_container">
              <p>{gitrank === 'not_top_50' || sorank === 'not_top_50' ? "Your ranking is NOT shared to LinkedIn and n" : "N"}o login with LinkedIn is required.<br/><b>This does NOT share anything to your feed and does NOT notify your network.</b><br/>You can edit the verified credential wording before adding it to your list of Licenses & Certifications.</p>
              <br/>
              <br/>
              <hr/>
            </div>



            <div className="titleContainer">

              {!type.both ? (
                // <div className="titleContainer">
                <>
                  <h2>or share{" "}
                    {type.string
                      ? `${isGithubRankNotInTop50 || isSlackRankNotInTop50 ? "" : `your ${type.string} rank & `}proof of profile ownership`
                      : SHARE_TITLE}
                  </h2>
                  </>
                  
                // </div>
              ) : (
                <h2>or share{" "}
                  {type.string
                    ? ` your ${type.string} rank & prove ownership of your profiles`
                    : ` ${SHARE_TITLE}`}
                </h2>
              )}
              

              {/* <span className="tooltip">
                  <img src={infoIcon} alt="info-icon" style={{ width: "20px", height: "20px"}}  />
                  <p className="tooltiptext">
                    Why? Stand out from your competition by adding your {APP_NAME}{" "}
                    link to your CV / LinkedIn / HackerRank / Email signature (we recommended instead of your{" "}
                    {DISPLAY_STRING_GH} link) to give potential employers confidence you own your profiles and can see your rank and soft skills at a glance
                  </p>
              </span> */}
              </div>
              </span>


              {/* If this is inside span, copy button is messed up */}
              <div className="inputContainer">
              <input
                disabled={true}
                type="text"
                value={`${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`}
              />
              <button
                type="button"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`
                  )
                }
              >
                <img src={copyIcon} alt="copy" />
              </button>
            </div>

            <span>
              <div className="titleContainer">
                <h3>
                  Stand out from your competition by adding your {APP_NAME}{" "}
                  link to your CV / LinkedIn / HackerRank / Email signature to give employers confidence you own your profiles & so they can see your {isGithubRankNotInTop50 || isSlackRankNotInTop50 ? '' : 'rank &'} soft skills at a glance.
                </h3>
              </div>
            </span>


        </div>
      </div>
    </div>
  );
};

export default Share;
