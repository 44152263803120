import React, { useEffect } from 'react';
import { GITHUB_STRING, SO_STRING } from '../../utils/serviceName';
import { createPopup } from './LoginPopup';

function LoginFromSharePopUp({ DISPLAY_STRING_GH, DISPLAY_STRING_SO, type, onCode }) {

    const url = type?.string === "GitHub" ? `https://stackoverflow.com/oauth/?client_id=${process.env.REACT_APP_STACKOF_ID}&redirect_uri=${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/stackoverflow-redirect` : `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_ID}&redirect_uri=${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/github-redirect` 

    const type_Da = type?.string === "GitHub" ? SO_STRING : GITHUB_STRING
    const title='Signing you in...'
    let externalWindow = null;
    const CODE_STRING = 'code';
	const STORAGE_STRING = 'storage';
    const width = 1000
    const height = 600

    useEffect(() => {
		return () => {
			if (externalWindow) {
				externalWindow.close();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (
        <label onClick={() => createPopup(width, height, type_Da, externalWindow, onCode, CODE_STRING, STORAGE_STRING, url, title)}>
            calculate your{" "}
            {type.string === DISPLAY_STRING_GH
                ? DISPLAY_STRING_SO
                : DISPLAY_STRING_GH}{" "}
                rank
        </label>
    );
}

export default LoginFromSharePopUp;