import React from 'react';
import LinkedInLogo from "../../images/linkedin.png"


function BtnLinkedIn({ LIBtnonclickFunc, classname, liShareUrl}) {

    const style = {
        color: 'black',
		background: '#fafbfc',
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
        width: "220px",
        padding: "15px 8px 15px 3px",
        lineHeight: '15px'
    }

    return (
        <button style={style} onClick={(e) => LIBtnonclickFunc(e, liShareUrl)} className={`${classname}`}>
            <img src={LinkedInLogo} alt='LinkedIn'/> Add free Verifiable<br/>Credential to LinkedIn<br/>Licenses & Certifications
        </button>
    );
}

export default BtnLinkedIn;