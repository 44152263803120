import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import axios from "axios";

const CreditDetail = (props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [credit, setCredit] = useState("");
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const updateOutstandingCredit = () => {
    const url = `${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/api/issue-credit`;
    let config = {
      headers: {
        "Content-Type": "application/json",
        auth: props.adminAuth,
      },
    };
    let data = {
      id: props.id,
      credit: parseInt(credit),
      client: "reputationaire-api",
    };
    axios
      .put(url, data, config)
      .then((resp) => {
        setSnackbarMsg("Credit Updated");
        setCredit("");
        setOpenSnackbar(true);
        props.updateList();
      })
      .catch((err) => {
        setSnackbarMsg("Error - please see console");
        console.log(err);
        setOpenSnackbar(true);
      });
  };

  return (
    <div className="detailItemContainer">
      <h2>Client Name: {props.clientName}</h2>
      <h3>UUID: {props.uuid}</h3>
      <div className="innerContainer">
        <div className="info">
          <p>Total Credit: {props.totalCredit}</p>
          <p>Outstanding: {props.outstandingCredit}</p>
        </div>
        <div className="info">
          <p>Company: {props.company}</p>
          <p>Email: {props.email}</p>
        </div>
        <div className="info">
          <p>Date Issued: {props.dateIssued}</p>
        </div>
      </div>
      <div className="inputContainer">
        <input
          className="creditInput"
          type="number"
          value={credit}
          placeholder="Enter new credit (integer)"
          onChange={(e) => {
            setCredit(e.target.value);
          }}
        />
        <div style={{ margin: "5px" }}></div>
        <button type="button" onClick={updateOutstandingCredit}>
          Update
        </button>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbarMsg}</span>}
      />
    </div>
  );
};

export default CreditDetail;
