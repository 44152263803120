import React, { useState, useEffect } from "react";
import CreditItem from "../../components/Admin/CreditItem";

const CreditDetailList = ({ adminAuth, creditList, updateList }) => {
  const [result, setResult] = useState(null);
  const [search, setSearch] = useState("");
  const find = (items, text) => {
    text = text.split(" ");
    return items.filter(function (item) {
      return text.every(function (el) {
        return item.client_name.toLowerCase().indexOf(el.toLowerCase()) > -1 || item.company.toLowerCase().indexOf(el.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(el.toLowerCase()) > -1 || item.uuid.toLowerCase().indexOf(el.toLowerCase()) > -1;
      });
    });
  };

  useEffect(() => {
    if (search) {
      const list = find(creditList, search);
      if (list.length !== 0) {
        setResult(list);
      } else {
        setResult(null);
      }
    } else {
      setResult(null);
    }
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <div className="wrapper detailsContainer">
        <div className="inputContainer">
          <input
            className="searchInput"
            type="text"
            value={search}
            placeholder="Search by name, company, email or UUID"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {(result || search !== "")
          ? result?.map((data) => {
              return (
                <CreditItem
                  key={data.id}
                  id={data.id}
                  uuid={data.uuid}
                  totalCredit={data.total_credit}
                  outstandingCredit={data.outstanding_credit}
                  clientName={data.client_name}
                  email={data.email}
                  company={data.company}
                  dateIssued={data.time_created}
                  updateList={updateList}
                  adminAuth={adminAuth}
                />
              );
            })
          : creditList.map((data) => {
              return (
                <CreditItem
                  key={data.id}
                  id={data.id}
                  uuid={data.uuid}
                  totalCredit={data.total_credit}
                  outstandingCredit={data.outstanding_credit}
                  clientName={data.client_name}
                  email={data.email}
                  company={data.company}
                  dateIssued={data.time_created}
                  updateList={updateList}
                  adminAuth={adminAuth}
                />
              );
            })}
      </div>
    </React.Fragment>
  );
};

export default CreditDetailList;
