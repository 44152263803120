import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import validator from "validator";
import IssueCreditUuid from "./IssueCreditUuid";
import CreditDetailList from "./CreditDetailList";
import errorIcon from "../../images/error.png";
import successIcon from "../../images/checked.png";
import loadingIcon from "../../images/sand-clock.png";

const Admin = (props) => {
  let authorized = true;
  if (props.adminAuth === "") {
    authorized = false;
  }

  const [creditList, setCreditList] = useState([]);
  const errorBoxRef = useRef(null);
  const successBoxRef = useRef(null);
  const loadingBoxRef = useRef(null);

  const updateList = () => {
    clearError();
    let config = {
      headers: {
        auth: props.adminAuth,
      },
    };
    axios
      .get(
        process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/issue-credit",
        config
      )
      .then((res) => {
        let credits = res.data.message.credits;
        credits.sort((a, b) => {
          if (a.time_created > b.time_created) {
            return -1;
          }

          if (a.time_created < b.time_created) {
            return 1;
          }

          return 0;
        });
        setCreditList(credits);
      })
      .catch((err) => {
        console.log(err);
        errorBoxRef.current.parentNode.classList.add("showErrorBox");
        errorBoxRef.current.innerHTML = err.message;
      });
  };

  useEffect(() => {
    updateList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validateEmail = (address) => {
    let allowSeeding =
      process.env.REACT_APP_TEST_MODE_ALLOW_SIGNUP_WITH_SEEDED_EMAILS;
    let seedCondition = true;
    if (!allowSeeding) {
      seedCondition = address.indexOf("+") === -1;
    }
    const isInputEmail = validator.isEmail(address) && seedCondition;
    const isValidAddress = address.match(/^X[a-zA-Z0-9]{33}$/) !== null;
    return isInputEmail || isValidAddress;
  };

  const isFormValid = (email, credit, clientName) => {
    let isValid = true;
    const EMPTY_CREDIT_STRING = "Please enter the amount of credit";
    const EMPTY_EMAIL_STRING = "Please enter your email";
    const EMPTY_NAME_STRING = "Please enter your name";
    const INVALID_NAME_STRING =
      "Please enter your name with no special characters or numbers";
    const INVALID_EMAIL_STRING =
      "Please enter your email in the correct format (you@domain.com)";
    if (email === "") {
      errorBoxRef.current.parentNode.classList.add("showErrorBox");
      if (errorBoxRef.current.innerHTML !== "") {
        errorBoxRef.current.innerHTML += "<br/>";
      }
      errorBoxRef.current.innerHTML += `• ${EMPTY_EMAIL_STRING}`;

      isValid = false;
    }

    if (credit === "" || parseInt(credit) === 0) {
      errorBoxRef.current.parentNode.classList.add("showErrorBox");
      if (errorBoxRef.current.innerHTML !== "") {
        errorBoxRef.current.innerHTML += "<br/>";
      }
      errorBoxRef.current.innerHTML += `• ${EMPTY_CREDIT_STRING}`;

      isValid = false;
    }

    if (clientName === "") {
      errorBoxRef.current.parentNode.classList.add("showErrorBox");
      if (errorBoxRef.current.innerHTML !== "") {
        errorBoxRef.current.innerHTML += "<br/>";
      }
      errorBoxRef.current.innerHTML += `• ${EMPTY_NAME_STRING}`;

      isValid = false;
    }

    if (clientName !== "" && !RegExp(/^['a-zA-Z-( )]+$/gm).test(clientName)) {
      errorBoxRef.current.parentNode.classList.add("showErrorBox");
      if (errorBoxRef.current.innerHTML !== "") {
        errorBoxRef.current.innerHTML += "<br/>";
      }
      errorBoxRef.current.innerHTML += `• ${INVALID_NAME_STRING}`;

      isValid = false;
    }

    if (email !== "" && !validateEmail(email)) {
      errorBoxRef.current.parentNode.classList.add("showErrorBox");
      if (errorBoxRef.current.innerHTML !== "") {
        errorBoxRef.current.innerHTML += "<br/>";
      }
      errorBoxRef.current.innerHTML += `• ${INVALID_EMAIL_STRING}`;

      isValid = false;
    }

    return isValid;
  };

  const clearError = () => {
    errorBoxRef.current.parentNode.classList.remove("showErrorBox");
    errorBoxRef.current.innerHTML = "";
  };

  const clearSuccess = () => {
    successBoxRef.current.parentNode.classList.remove("showSuccessBox");
  };

  const showSuccess = (name, uuid) => {
    successBoxRef.current.innerHTML = `Credit has been issued to ${name} with uuid: ${uuid}`;
    successBoxRef.current.parentNode.classList.add("showSuccessBox");
  };

  const showLoading = () => {
    loadingBoxRef.current.classList.add("showLoadingBox");
  };

  const stopLoading = () => {
    loadingBoxRef.current.classList.remove("showLoadingBox");
  };

  /* Issue Credit button click function  */
  const issueCreditClicked = (clientName, email, company, credit) => {
    clearError();
    if (!isFormValid(email, credit, clientName)) {
      return Promise.reject();
    }

    showLoading();
    let config = {
      headers: {
        "Content-Type": "application/json",
        auth: props.adminAuth,
      },
    };
    let data = {
      name: clientName,
      email: email,
      company: company,
      credit: parseInt(credit),
      client: "reputationaire-api",
    };

    return axios
      .post(
        `${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/api/issue-credit`,
        data,
        config
      )
      .then((res) => {
        const uuid_credit = res.data.message.uuid_credit;
        stopLoading();
        showSuccess(clientName, uuid_credit);
        clearError();
        updateList();
      })
      .catch((err) => {
        console.log(err);
        stopLoading();
        errorBoxRef.current.parentNode.classList.add("showErrorBox");
        errorBoxRef.current.innerHTML = err.message;
      });
  };

  return (
    <>
      <div className="wrapper adminContainer">
        {!authorized ? (
          <>
            <div />
            <div className="unauthorizedMessage">
              You are not logged in, <a href="/login">please login</a>
            </div>
            <div />
          </>
        ) : (
          <>
            <IssueCreditUuid
              adminAuth={props.adminAuth}
              issueCreditClicked={issueCreditClicked}
              creditList={creditList}
            />
            <CreditDetailList
              creditList={creditList}
              updateList={updateList}
              adminAuth={props.adminAuth}
            />
          </>
        )}
      </div>
      <div ref={loadingBoxRef} className="loadingBox">
        <img src={loadingIcon} alt="loading" />
        <p>Please wait</p>
      </div>
      <div className="errorBox">
        <img src={errorIcon} alt="error" />
        <p ref={errorBoxRef}></p>
        <button onClick={clearError} type="button">
          OK
        </button>
      </div>
      <div className="successBox">
        <img src={successIcon} alt="success" />
        <p ref={successBoxRef}></p>
        <button onClick={clearSuccess} type="button">
          OK
        </button>
      </div>
    </>
  );
};

export default Admin;
