/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import infoIcon from "../../images/infoIcon.svg";
import gitHubLogo from "../../images/gitHubLogo.svg";
import profileImg from "../../images/profile-img.jpeg";
import stackoverflowLogo from "../../images/stackoverflowLogo.svg";
import searchIcon from "../../images/search.png";
import attitudeIcon from "../../images/attitude.png";
import communityIcon from "../../images/community.png";
import passionIcon from "../../images/passion.png";
import commIcon from "../../images/comm.png";
import trustIcon from "../../images/trust.png";
import qaIcon from "../../images/qa.png";
// import shareIcon from "../../images/share1.png";
import newTabIcon from "../../images/newtab.png";
import Congrats from "../../images/congrats.png";
import LinkedInLogo from "../../images/linkedin.png";

import Nav from "../../components/RequestRating/Nav";
import Drawer from "../../components/RequestRating/Drawer";
import Footer from "../../components/RequestRating/Footer";

import LoginGitHub from "../../components/RequestRating/LoginGithub";
import LoginStackOverflow from "../../components/RequestRating/LoginStackoverflow";
import PaymentsModal from "../../components/RequestRating/PaymentsModal";

import Share from "../../components/RequestRating/Share";
import ErrorCard from "../../components/ErrorCard";
import LoadingAnimation from "../../components/LoadingAnimation";
import axios from "axios";
import { AiTwotoneMail } from 'react-icons/ai'

import {
  TOTAL_GITHUB_USERS,
  TOTAL_STACKOVERFLOW_USERS,
} from "../../utils/totalNumberUsers";

import {
  APP_NAME,
  DISPLAY_STRING_GH,
  DISPLAY_STRING_SO,
  GITHUB_STRING,
  SO_STRING,
} from "../../utils/serviceName";
import LEVEL_DIALOG from "../../components/Level_dialog";
import OtherRankingRegister from "../../components/OtherRankingRegister";


// 2021-04-012 relative-ratings-app RequestRating.js and relative-rankings-chrome-extension functions.js share this same code => move to shared component

// regex to check if the entered str is github url
const checkValidGitHubUrl = (str) => {
  const pattern = new RegExp(
    "^(http://www.|https://www.|http://|https://)?" + // eslint-disable-line
      "github.com/.+"
  ); // domain name
  return pattern.test(str);
};

// regex to check if the entered str is stackoverflow url
const checkValidStackOverflowUrl = (str) => {
  const pattern = new RegExp(
    "^(http://www.|https://www.|http://|https://)?" + // eslint-disable-line
      "stackoverflow.com/.+"
  ); // domain name
  return pattern.test(str);
};

// extract whatever is after https://github.com/ or github.com/
const extractUsername = (url) => {
  const split = url.toString().split("/");
  if (split[3]) {
    return split[3];
  }

  return split[1];
};

const extractUserId = (url) => {
  let userIDValue = "";
  const urlseg = url.toString().split("/");
  for (let i = 0; i < urlseg.length; i++) {
    if (!isNaN(urlseg[i])) {
      userIDValue = urlseg[i];
    }
  }

  return userIDValue;
};












const checkValidUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
};


const fixedEncodeURIComponent = (str) => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
};

const crunchOwnRating = (githubToken) => {
  const data = {
    token: githubToken,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/request-own-rating";
  return axios.post(requestUrl, data, config);
};

const crunchOtherRating = (githubId) => {
  const data = {
    githubId: githubId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/request-rating";

  return axios.post(requestUrl, data, config);
};

const crunchOwnRatingStackOverflow = (stackoverflowToken) => {
  const data = {
    token: stackoverflowToken,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
    "/api/stackoverflow/request-own-rating";
  return axios.post(requestUrl, data, config);
};

const crunchOtherRatingStackOverflow = (stackoverflowId) => {
  const data = {
    stackoverflowId: stackoverflowId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
    "/api/stackoverflow/request-rating";

  return axios.post(requestUrl, data, config);
};

//Retrieve GitHub username from login
const getGitHubUsername = (githubToken) => {
  const data = {
    token: githubToken,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
    "/api/get-github-username";
  return axios.post(requestUrl, data, config);
};

//Retrieve StackOverflow username from login
const getStackOverflowUsername = (stackoverflowToken) => {
  const data = {
    token: stackoverflowToken,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
    "/api/stackoverflow/get-so-username";
  return axios.post(requestUrl, data, config);
};

//Retrieve StackOverflow username from userID
const getStackOverflowUsername_userid = (stackoverflowId) => {
  const data = {
    stackoverflowId: stackoverflowId,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
    "/api/stackoverflow/get-so-username";
  return axios.post(requestUrl, data, config);
};

const generate_profile = (github_token, stackoverflow_token) => {
  const data = {
    github_token,
    stackoverflow_token,
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const requestUrl =
    process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/generate-profile";
  return axios.post(requestUrl, data, config);
};

const RequestRating = (props) => {
  const history = useHistory();
  const [showShare, setShowShare] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [showError, setShowError] = useState({ show: false, msg: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [resultStackOverflow, setResultStackOverflow] = useState("");
  const [username, setUsername] = useState("");
  //Get/set Stack Overflow display name
  const [SOusername, setSOusername] = useState("");
  //Get/set StackOverflow userID
  const [userid, setUserId] = useState("");

  const [ownRatingGH, setOwnRatingGH] = useState(false);
  const [ownRatingSO, setOwnRatingSO] = useState(false);
  const [hash, setHash] = useState("");
  const [hideSO, setHideSO] = useState(false);
  const [hideGH, setHideGH] = useState(false);
  const [uuidCredit, setUuidCredit] = useState("");
  const [outstandingCredit, setOutstandingCredit] = useState(0);
  const [githubToken, setGitHubToken] = useState("");
  const [stackoverflowToken, setStackOverflowToken] = useState("");

  const [drawerVisible, toggleDrawer] = useState(false);

  const [numMilUsers, setNumMilUsers] = useState(0);
  const [nameOfSite, setNameOfSite] = useState(0);
  const [loadingProfileStr, setLoadingProfileStr] = useState("");
  const [openDialog, setOpenDialog] = useState(false)
  const [expectedLevelGH, setExpectedLevelGH] = useState(null)
  const [expectedLevelAndResGH, setExpectedLevelAndResGH] = useState(null)
  const [expectedLevelSO, setExpectedLevelSO] = useState(null)
  const [expectedLevelAndResSO, setExpectedLevelAndResSO] = useState(null)
  const [github_search, setGithubSearch] = useState(null)
  const [retrieved_github_user_email, setRetrieved_github_user_email] = useState(null)
  const [gHAdditionalRankingData, setGHAdditionalRankingData] = useState(null)
  
  const [stackoverflowSearch, setStackOverFlowSearch] = useState(false)

  useEffect(() =>  {
    if(result !== "" && hideGH === true && uuidCredit) setHideGH(false)
    if(resultStackOverflow !== "" && hideSO === true && uuidCredit) setHideSO(false)
  }, [result, hideGH, resultStackOverflow, hideSO, uuidCredit])

  useEffect(() => {
    if(github_search === false && result !== "" && resultStackOverflow !== "" && expectedLevelAndResSO !== null && openDialog === false && isLoading === false){
      document.getElementById('so_section').scrollIntoView()
    }
  }, [github_search, result, resultStackOverflow, expectedLevelAndResSO, openDialog, isLoading])

  useEffect(() => {
    if(expectedLevelGH !== null){
      if(expectedLevelGH === "intern" && parseFloat(result) < 80) setExpectedLevelAndResGH(true)
      else if(expectedLevelGH === "senior" && parseFloat(result) < 20) setExpectedLevelAndResGH(true)
      else if(expectedLevelGH === "mid-level" && parseFloat(result) < 40) setExpectedLevelAndResGH(true)
      else if(expectedLevelGH === "junior" && parseFloat(result) < 60) setExpectedLevelAndResGH(true)
      else setExpectedLevelAndResGH(false)
    }
  }, [expectedLevelGH, expectedLevelAndResGH, result])
  useEffect(() => {
    if(expectedLevelSO !== null){
      if(expectedLevelSO === "intern" && parseFloat(resultStackOverflow) < 80) setExpectedLevelAndResSO(true)
      else if(expectedLevelSO === "senior" && parseFloat(resultStackOverflow) < 20) setExpectedLevelAndResSO(true)
      else if(expectedLevelSO === "mid-level" && parseFloat(resultStackOverflow) < 40) setExpectedLevelAndResSO(true)
      else if(expectedLevelSO === "junior" && parseFloat(resultStackOverflow) < 60) setExpectedLevelAndResSO(true)
      else setExpectedLevelAndResSO(false)
    }
  }, [expectedLevelSO, expectedLevelAndResSO, resultStackOverflow])

  // Return if we could introduce the techi to the recruiter
  const Contact_us_about_techi = (github) => {
    const href_content = `mailto:introductions@techierank.com?subject=Please facilitate an introduction to GitHub user ${username}&body=I would like an introduction to GitHub user ${username} because ...`;

    if (retrieved_github_user_email === true && github === true) {
      return <span className="congrats email_congrats" style={{padding: expectedLevelAndResGH === true ? "0 20px 0 20px": "0 20px 2rem 20px"}}>
        <AiTwotoneMail />&nbsp;We maybe able to facilitate an introduction to this Techie - please&nbsp;<a href={href_content} target="_blank" rel="noopener noreferrer">let us know why you'd like to be introduced</a>?
        <br/><p style={{color: "white"}}>(subscribers can contact Techies directly)</p>
      </span>
    } else {
      return <></>
    }
  }

  // Return the congrats message
  const Congrats_Msg = (github) => {
    return <>
            <div className="congrats">
              <img src={Congrats} alt="Congrats" />&nbsp;Congratulations you may have found a diamond in the rough!
            </div> 
              {github === true ? <p className="candidate__info">Your Techie is above the typical top {expectedLevelGH === "intern" ? "100-80%" : expectedLevelGH === "junior" ? "80-60%" : expectedLevelGH === "mid-level" ? '60-40%' : '40-20%'} range for {expectedLevelGH} level developers.<br/><br/></p> : 
              <p className="candidate__info">Your Techie is above the typical top {expectedLevelSO === "intern" ? "100-80%" : expectedLevelSO === "junior" ? "80-60%" : expectedLevelSO === "mid-level" ? '60-40%' : '40-0%'} range for {expectedLevelSO} level developers.<br/><br/></p>}
              {/* {Contact_us_about_techi(github)} */}
            
           </>
  }
  // Return Not enough data message
  const NotEnoughData_Msg = (github) => {
    return <>
            <div className="non-congrats">
              <p>Not enough data to tell if this Techie may be a diamond in the rough or not.</p>
            </div>
            {Contact_us_about_techi(github)}
           </>
  }

  const RecruiterOrEmployerSalesTitleText = () => {
    return `Employ developers? Wish you could get an instant technical & soft skills assessment of a candidate?`;
  };


  const RecruiterOrEmployerSalesText = () => {

    return <>
      <p className="p-other-desc">
      TechieRank is a novel pre-employment developer assessment platform.
      <br/><br/>
      Gain a strategic hiring advantage by uncovering hidden data points about your candidates by checking their ranking and soft skills on {DISPLAY_STRING_GH} or{" "} {DISPLAY_STRING_SO}.
      <br/><br/>
      TechieRank makes it quick and easy (even as a non techincal applicant assessor) to instantly spot your diamond in the rough applicants!
      <br/><br/>
      <img src="/techierank.gif" alt="Check any developer's tech and soft skills" style={{width: "80%"}}/>
      <br/>
      <br/>
      Developers do not need to be members of our service - it works on any of the {TOTAL_GITHUB_USERS + TOTAL_STACKOVERFLOW_USERS} million developers with a public {DISPLAY_STRING_GH} or{" "} {DISPLAY_STRING_SO} profile!
      <br/><br/>
      {/* Do your traditional candidate screening processes rely on outdated Cover Letters, CVs & LinkedIn profiles resulting in diamond in the rough developers slipping through your fingers?
      <br/><br/> */}
      Studies show many great developers (often from more diverse backgrounds) don't have good CVs or LinkedIn profiles but have proof of tech and soft skills (such as ability to solve problems themselves, communication skills e.t.c) on {DISPLAY_STRING_GH} or{" "} {DISPLAY_STRING_SO} that TechieRank uncovers for you.
    <br/></p>
    </>;

  };


  // Change the state(opendialog, ExpectedLevelAndResSO and ExpectedLevelAndResGH) and open the dialog
  const changeStateAndOpenDialog = (github) => {
    if(localStorage.getItem('uuidCredit') !== "" && localStorage.getItem('uuidCredit') !== null){
      if(github === true){
        setExpectedLevelAndResGH(null);
        setOpenDialog(true);
      }else{
        setExpectedLevelAndResSO(null);
        setOpenDialog(true);
      }
    }
  }
  //Payment modal
  const [modalShow, setModalShow] = useState(false);

  const loadingTextStr =
    "Crunching ".concat(numMilUsers.toString()) +
    " million " +
    nameOfSite.toString() +
    " Techie profiles....";
  const NOT_TOP_50 = "not_top_50";

  const navigateToRegister = () => history.push("/register");
  const setToken = ({ type, token }) => {
    if (type === GITHUB_STRING) {
      setGitHubToken(token);
    } else if (type === SO_STRING) {
      setStackOverflowToken(token);
    }
  };

  // parse the token passed from github (if any) and update username from the api
  // e.g. http://localhost:3000/?access_token=asdfasdf
  // this is a onetime check on component creation
  useEffect(() => {
    const ACCESS_STRING = "access";
    const query = new URLSearchParams(props.location.search);
    let params = {};
    for (let param of query.entries()) {
      params[param[0]] = param[1];
    }

    if (params.hasOwnProperty(ACCESS_STRING)) {
      // Store the uuidcredit in the local storage
      localStorage.setItem('uuidCredit', params[ACCESS_STRING])
      window.location.href = process.env.REACT_APP_URL_RELATIVE_RATINGS_APP
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // To log out the user
  const logout = () => {
    localStorage.removeItem('uuidCredit')
    window.location.href = process.env.REACT_APP_URL_RELATIVE_RATINGS_APP
  }

  useEffect(() => {
    // Check the local storage
    if(localStorage.getItem('uuidCredit')){
      setUuidCredit(localStorage.getItem('uuidCredit'))
      initOutstandingCredits(localStorage.getItem('uuidCredit'));
      setHideGH(true);
      setHideSO(true);
    }
  }, [])

  useEffect(() => {
    if (props.location.hash) {
      const profileURL = props.location.hash.slice(1);
      const profileProps = profileURL.split("/");

      if (profileProps.length < 2) {
        setShowError({
          show: true,
          msg: "Invalid Profile Link",
        });
      } else {
        if (profileProps.length === 3) {
          const [github, so] = profileProps;
          if (!_.isInteger(Number(github)) && _.isInteger(Number(so))) {
            setGithubSearch('both')
            getProfile.apply(this, profileProps);
            setLoadingProfileStr(`
              Crunching ${TOTAL_GITHUB_USERS} million  
              ${DISPLAY_STRING_GH} and ${TOTAL_STACKOVERFLOW_USERS} million  
              ${DISPLAY_STRING_SO}
              Techie profiles....`
            );
          } else {
            setShowError({
              show: true,
              msg: "Invalid Profile Link",
            });
          }
        } else {
          const [profile, hash] = profileProps;
          if (_.isInteger(Number(profile))) {
            getProfile("", profile, hash);
            setGithubSearch(false)
            setLoadingProfileStr(`
              Crunching ${TOTAL_STACKOVERFLOW_USERS} million  
              ${DISPLAY_STRING_SO}
              Techie profiles....`
            );
          } else if (!_.isInteger(Number(profile))) {
            setGithubSearch(true)
            getProfile(profile, "", hash);
            setLoadingProfileStr(`
              Crunching ${TOTAL_GITHUB_USERS} million  
              ${DISPLAY_STRING_GH}
              Techie profiles....`
            );
          } else {
            setShowError({
              show: true,
              msg: "Invalid Profile Link",
            });
          }
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // auto check for login with github
  useEffect(() => {
    if (githubToken) {
      crunchRating();
    }
  }, [githubToken]); // eslint-disable-line react-hooks/exhaustive-deps

  // auto check for login with stackoverflow
  useEffect(() => {
    if (stackoverflowToken) {
      crunchRatingStackoverflow();
    }
  }, [stackoverflowToken]); // eslint-disable-line react-hooks/exhaustive-deps

  // Check if the user is visiting his own profile
  useEffect(() => {
    if((ownRatingGH !== true && result !== "" && hash !== "") || (ownRatingSO !== true && resultStackOverflow !== "" && hash !== "")){
      if(localStorage.getItem('techierank_github_profile_username') === username || localStorage.getItem('techierank_stackoverflow_profile_username') === SOusername){
        setShowShare(true)
      }
    }
  }, [username, SOusername, ownRatingGH, ownRatingSO, result, resultStackOverflow, hash])

  useEffect(() => {
    if(ownRatingSO === true && stackoverflowSearch === true){
      document.getElementById('so_section').scrollIntoView()
    }
  }, [ownRatingSO, stackoverflowSearch])

  // a user input check
  // if enteredVal is a valid github url then it will automatically extract the username
  // otherwise it treats whatever the user is entering as username
  const checkForUsername = () => {
    const enteredVal = enteredText.trim();

    if (!enteredVal) {
      setShowError({
        show: true,
        msg: "the URL or username that you entered does not seem to be valid",
      });

      return;
    }

    setShowError({ show: false, msg: "" });
    // check if user entered a url
    const isValidUrl = checkValidUrl(enteredVal);
    if (isValidUrl) {


      // 2021-04-012 relative-ratings-app RequestRating.js and relative-rankings-chrome-extension functions.js share this same code => move to shared component
      // check if the entered url is actually github or SO
      const isValidGithub = checkValidGitHubUrl(enteredVal);
      const isValidSO = checkValidStackOverflowUrl(enteredVal);


      if (isValidGithub) {
        setGithubSearch(true)
        const newUsername = extractUsername(enteredVal);
        crunchRating(newUsername, true);
      } else if (isValidSO) {
        setGithubSearch(false)
        const newUserId = extractUserId(enteredVal);
        crunchRatingStackoverflow(newUserId, true);
      } else {
        setShowError({
          show: true,
          msg: "the URL that you entered does not seem to be valid",
        });
      }
    } else {
      if (Number.isInteger(Number(enteredVal))) {
        setGithubSearch(false)
        setUserId(enteredVal);
        crunchRatingStackoverflow(enteredVal);
      } else {
        setGithubSearch(true)
        crunchRating(enteredVal);
      }
    }
  };

  const twitterShare = () => {
    const profileUrl = `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`;
    let rankText = "";
    if (result && result !== NOT_TOP_50) {
      rankText += `top ${result}% of ${DISPLAY_STRING_GH} developers `;
    }
    if (resultStackOverflow && resultStackOverflow !== NOT_TOP_50) {
      if (rankText !== "") {
        rankText += "and ";
      }
      rankText += `top ${resultStackOverflow}% of ${DISPLAY_STRING_SO} developers `;
    }
    const text = encodeURIComponent(
      `${
        rankText === ""
          ? `Check out my developer profile(s) on ${APP_NAME}.com!`
          : `I'm ranked in the ${rankText}on ${APP_NAME}.com - Check your rank free now!`
      } \n${profileUrl} \n\n@${APP_NAME} powered by @reputationaire`
    );

    const url = `https://twitter.com/intent/tweet?text=${text}`;
    window.open(url, "_blank");
  };

  const linkedInShare = () => {
    const profileUrl = fixedEncodeURIComponent(
      `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`
    );
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${profileUrl}`;
    window.open(url, "_blank");
  };

  const crunchRating = async (user = "", search = false) => {
    // check if username is actually set
    // user = user !== '' ? user : username;
    setShowError({ show: false, msg: "" });
    setShowShare(false);

    if (user === "" && githubToken === "") {
      setShowError({
        show: true,
        msg: `Please enter a valid username/${DISPLAY_STRING_GH} URL`,
      });
      return;
    }

    setNumMilUsers(TOTAL_GITHUB_USERS);
    setNameOfSite(DISPLAY_STRING_GH);
    setIsLoading(true);
    var user_name = ""
    if (githubToken && !search) {
      // GET AND SET GITHUB USERNAME
      await getGitHubUsername(githubToken).then((res) => {
        setUsername(_.unescape(res.data.message.username));
        user_name = _.unescape(res.data.message.username)
      });

      crunchOwnRating(githubToken)
        .then((res) => {
          let average = res.data.message.average;
          let newResult = (average === 100 ? average : average * 100).toFixed(
            2
          );

          if (newResult > 50) {
            newResult = NOT_TOP_50;
          } else if (newResult === 0.0) {
            newResult = 0.01;
          }

          setOwnRatingGH(true);
          setResult(newResult);
          setIsLoading(false);

          if (!uuidCredit) {
            generate_profile(githubToken, stackoverflowToken).then((res) => {
              const hash = res.data.message.profile;
              setHash(hash);
              setShowShare(true);
              window.history.pushState(
                {},
                `Your ${APP_NAME} Profile`,
                `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`
              );
            });
          }
          if(user_name !== ""){
            localStorage.setItem('techierank_github_profile_username', user_name)
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 404) {
              setShowError({ show: true, msg: "No such username" });
              return;
            }
          }
          setShowError({
            show: true,
            msg: `We're having trouble completing your request at this time`,
          });
        });
    } else {
      crunchOtherRating(user)
        .then((resp) => {
          const uuid_github = resp.data.message.uuid_github;
          axios
            .get(
              process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
                `/api/result?id=${uuid_github}&access=${uuidCredit}`
            )
            .then((res) => {
              if (res.data.message.credit_exhausted === true) {
                setResult("");
                setIsLoading(false);
                setModalShow(true);
                return;
              }

              let average = (res.data.message.average === 100
                ? res.data.message.average
                : res.data.message.average * 100
              ).toFixed(2);

              if (average === 0.0) {
                average = 0.01;
              }

              const outstandingCredit = res.data.message.credit_remaining;

              setRetrieved_github_user_email(res.data.message.got_email)
              setOwnRatingGH(false);
              setUsername(_.unescape(user));
              setResult(average);
              setOutstandingCredit(outstandingCredit);
              setIsLoading(false);
              if (hideGH) {
                setHideGH(false);
              }
              // setExpectedLevelAndResGH(null);
              // setOpenDialog(true);
              changeStateAndOpenDialog(true)

setGHAdditionalRankingData(res.data.message.ratingData);


            })
            .catch((err) => {
              setIsLoading(false);
              setShowError({
                show: true,
                msg: "Please check the url/id and submit again.",
              });
            });
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 404) {
              setShowError({ show: true, msg: "No such username" });
              return;
            }
          }
          setShowError({
            show: true,
            msg: `We're having trouble completing your request at this time`,
          });
        });
    }
  };

  const getProfile = (github = "", userid = "", hash) => {
    setIsLoading(true);
    const requestUrl =
      process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/check-profile";

    const data = {
      github,
      stackoverflow: userid,
      hash,
      uuidCredit: localStorage.getItem('uuidCredit'),
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .post(requestUrl, data, config)
      .then((res) => {
        if (res.data.message.so_average !== "") {
          let so_average = res.data.message.so_average.toFixed(2);
          if (so_average === 0.0) {
            so_average = 0.01;
          }

          setSOusername(_.unescape(res.data.message.so_username));
          setUserId(userid);
          setResultStackOverflow(so_average);
          setOwnRatingSO(false);
          // setExpectedLevelAndResSO(null);
          // setOpenDialog(true);
          changeStateAndOpenDialog(false)
        }

        if (res.data.message.github_average !== "") {
          let github_average = (res.data.message.github_average === 100
            ? res.data.message.github_average
            : res.data.message.github_average * 100
          ).toFixed(2);
          if (github_average === 0.0) {
            github_average = 0.01;
          }

          setRetrieved_github_user_email(res.data.message.got_email)
          setUsername(_.unescape(github));
          setOwnRatingGH(false);
          setResult(github_average);
          // setExpectedLevelAndResGH(null);
          // setOpenDialog(true);
          const hash = "#"+window.location.href.split('#')[1]
          setHash(hash)
          changeStateAndOpenDialog(true)
        }
        setIsLoading(false);
      })
      .catch((err) => {

console.error(err);

        setIsLoading(false);
        setShowError({ show: true, msg: err.response.data.message.status });
      });
  };

  const crunchRatingStackoverflow = async (user = "", search = false) => {
    // check if userid is actually set
    // user = user !== '' ? user : userid;
    setShowError({ show: false, msg: "" });
    setShowShare(false);
    if (user === "" && stackoverflowToken === "") {
      setShowError({
        show: true,
        msg: `Please enter a valid userID/${DISPLAY_STRING_SO} URL`,
      });
      return;
    }

    setNumMilUsers(TOTAL_STACKOVERFLOW_USERS);
    setNameOfSite(DISPLAY_STRING_SO);
    setIsLoading(true);
    var user_name = ""

    if (stackoverflowToken && !search) {
      await getStackOverflowUsername(stackoverflowToken).then((res) => {
        setSOusername(_.unescape(res.data.message.username));
        setUserId(res.data.message.user_id);
        user_name = _.unescape(res.data.message.username)
      });

      crunchOwnRatingStackOverflow(stackoverflowToken)
        .then((res) => {
          let average = res.data.message.average;
          let newResult = average.toFixed(2);

          if (newResult > 50) {
            newResult = NOT_TOP_50;
          } else if (newResult === 0.0) {
            newResult = 0.01;
          }

          setOwnRatingSO(true);
          setResultStackOverflow(newResult);
          setIsLoading(false);

          if (!uuidCredit) {
            generate_profile(githubToken, stackoverflowToken).then((res) => {
              const hash = res.data.message.profile;
              setHash(hash);
              setShowShare(true);
              window.history.pushState(
                {},
                `Your ${APP_NAME} Profile`,
                `${process.env.REACT_APP_URL_RELATIVE_RATINGS_APP}${hash}`
              );
            });
          }
          if(user_name !== ""){
            localStorage.setItem('techierank_stackoverflow_profile_username', user_name)
          }
          if(stackoverflowSearch === false){
            setStackOverFlowSearch(true)
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 404) {
              setShowError({ show: true, msg: "No such username" });
              return;
            }
          }
          setShowError({
            show: true,
            msg: `We're having trouble completing your request at this time`,
          });
        });
    } else {
      getStackOverflowUsername_userid(user).then((res) => {
        setSOusername(_.unescape(res.data.message.username));
      });

      crunchOtherRatingStackOverflow(user)
        .then((resp) => {
          const uuid_stackoverflow = resp.data.message.uuid_stackoverflow;
          axios
            .get(
              process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE +
                `/api/stackoverflow/result?id=${uuid_stackoverflow}&access=${uuidCredit}`
            )
            .then((res) => {
              if (res.data.message.credit_exhausted === true) {
                setResultStackOverflow("");
                setIsLoading(false);
                setModalShow(true);
                return;
              }
              let average = res.data.message.average.toFixed(2);
              const outstandingCredit = res.data.message.credit_remaining;

              if (average === 0.0) {
                average = 0.01;
              }

              setOwnRatingSO(false);
              setUserId(user);
              setResultStackOverflow(average);
              setOutstandingCredit(outstandingCredit);
              setIsLoading(false);
              if (hideSO) {
                setHideSO(false);
              }
              // setExpectedLevelAndResSO(null);
              // setOpenDialog(true);
              changeStateAndOpenDialog(false)
            })
            .catch((err) => {
              setIsLoading(false);
              setShowError({
                show: true,
                msg: "Please check the url/id and submit again.",
              });
            });
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response) {
            if (err.response.status === 404) {
              setIsLoading(false);
              setShowError({ show: true, msg: "No such username" });
              return;
            }
          }
          setIsLoading(false);
          setShowError({
            show: true,
            msg: `We're having trouble completing your request at this time`,
          });
        });
    }
  };


  const initOutstandingCredits = (uuidCredit) => {
    let requestUrl =
      process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE + "/api/get-user-info";

    let data = {
      access: uuidCredit,
    };

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios.post(requestUrl, data, config).then((res) => {
      setOutstandingCredit(res.data.message.outstanding_credit);
    });
  };


  const RankingTooltipGH = () => {

  return <span className="tooltip">
    <img src={infoIcon} alt="info-icon" />
    <p className="tooltiptext">
      {DISPLAY_STRING_GH} is where over {TOTAL_GITHUB_USERS} pesudo anonymous million developers contribute to the open source community &amp; manage their code repositories
    </p>
   </span>


  };

  // Get the number of people user is higher than
  const higher_than = (ranking, total) => {
    let int_ranking = parseInt(ranking)
    return total - parseInt((total*int_ranking)/100)
  }


const parseTopByInToHighMediumLow = (topString) => {

  const topPercentage = Math.min(100, parseInt(topString.replace('top ', '').split('.')[0]));

  return topPercentage >= 66 ? 'low' : topPercentage >= 33 ? 'medium' : 'high';

};


  const RankingGHOrSO = (rankingAndOwnRatingAndGHElseSO) => {

    var resu = 0
    if(rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] === true && result !== 'not_top_50'){
      resu = higher_than(result, TOTAL_GITHUB_USERS)
    }else if(rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] === false && resultStackOverflow !== 'not_top_50'){
      resu = higher_than(resultStackOverflow, TOTAL_STACKOVERFLOW_USERS)
    }


const ghAccountAge = gHAdditionalRankingData?.since ? ' (' + gHAdditionalRankingData.since.split(' ')[0].replace('+', '') + ' months)' : false;
const ghTopByRepos = gHAdditionalRankingData?.user_byrepos ? parseTopByInToHighMediumLow(gHAdditionalRankingData.user_byrepos) + ' ' : false;
const ghTopByStars = gHAdditionalRankingData?.toprepo_bystars ? parseTopByInToHighMediumLow(gHAdditionalRankingData.toprepo_bystars) + ' number of ' : false;
const ghTopByFollowers = gHAdditionalRankingData?.user_byfollowers ? 'a ' + parseTopByInToHighMediumLow(gHAdditionalRankingData.user_byfollowers) + ' ' : false;

    return <>

      { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[0] === NOT_TOP_50 ? (

        <span>
          <h2>Profile ownership verified!</h2>
          { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] ? <RankingTooltipGH/> : <RankingTooltipSO/> }
        </span>

        ) : (

          <>
            <p className="p1 p1_top_part">
              {rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[1] ? "Congratulations! You are r" : "R"}anked
              in the
            </p>
            <span>
              <h2>Top {rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[0]}% of { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] ? TOTAL_GITHUB_USERS : TOTAL_STACKOVERFLOW_USERS } million { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] ? DISPLAY_STRING_GH : DISPLAY_STRING_SO } users</h2>
              { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] ? <RankingTooltipGH/> : <RankingTooltipSO/> }
            </span>
          </>

        )}

        <p className="p1">{rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[0] === NOT_TOP_50 ? `You are not in the top 50%... yet!` : resu !== 0 && `(so above about ${resu} million developers)`}
</p>


        { // TODO use this GH ranking message format everywhere not just employer's dashboard

          rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] === true ? <span className="tooltip">
          <p className="tooltiptext tooltiptext_ranking" >
            <br/>
            Ranking is based on {username}'s account age{ghAccountAge ? ghAccountAge : ''}, {ghTopByRepos ? ghTopByRepos : ''}number of contributions to code repositories, {ghTopByStars ? ghTopByStars : ''}repository stars (showing quality of projects) and {ghTopByFollowers ? ghTopByFollowers : ''}following.
          </p>
          </span> :
          <span className="tooltip">
            <p className="tooltiptext tooltiptext_ranking">
              <br/>
              Ranking is based on reputation on {DISPLAY_STRING_SO} determined by community votes on quality questions, answers and comments.
            </p>
          </span>

        }

{
rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[3] === true && Congrats_Msg(rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] === true)
}

{(
  rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] === true && expectedLevelAndResGH === true) && Contact_us_about_techi(true)
}


        { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[1] ? (

<>
<br/>
<p className="p1-job-hunting"><span>Job Hunting?</span><br/> { rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[2] ? 
<>Recruiters & employers check {DISPLAY_STRING_GH} and like to see your progress. <br />Stand out from your peers in the 6 seconds your application is reviewed by {rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[0] === NOT_TOP_50 
  ? `uploading your own projects (and setting your private activity public) or contributing to open source projects and share proof of your ${DISPLAY_STRING_GH} ownership`
  : `sharing your proof of rank`} on your CV or on LinkedIn via our free Verifiable Credential!</>
: <>Recruiters & employers tell us that contributing on {DISPLAY_STRING_SO} makes you stand out from your peers in the 6 seconds your application is reviewed. <br />{rankingAndOwnRatingAndGHElseSO.rankingAndOwnRatingAndGHElseSO[0] === NOT_TOP_50
  ? `Try to ask good questions or answer or comment on others' questions and share proof of your ${DISPLAY_STRING_SO} ownership` 
  : `Share your proof of rank`} on your CV or on LinkedIn via our free Verifiable Credential!</>
}
</p>
<br/>
</>

        ) : (null) }

      </>

  };




  const RankingTooltipSO = () => {

    return <span className="tooltip">
      <img src={infoIcon} alt="info-icon" />
      <p className="tooltiptext">
      {DISPLAY_STRING_SO} is the largest pseudo anonymous community for {TOTAL_STACKOVERFLOW_USERS} million developers to learn &amp; share​ ​their programming ​knowledge
      </p>
     </span>
  
  
    };








  return (
    <>
      {drawerVisible ? (
        <Drawer
          isCredit={uuidCredit}
          credit={outstandingCredit}
          showPayment={() => setModalShow(true)}
          goToRegister={() => navigateToRegister()}
          closeDrawer={() => toggleDrawer(false)}
          logout={() => logout()}
        />
      ) : null}
      <div className="wrapper">
        <Nav
          isCredit={uuidCredit}
          credit={outstandingCredit}
          showPayment={() => setModalShow(true)}
          goToRegister={() => navigateToRegister()}
          openDrawer={() => toggleDrawer(true)}
          logout={() => logout()}
        />
        {modalShow ? (
          <PaymentsModal hidePayment={() => setModalShow(false)} />
        ) : null}
        {isLoading ? (
          <LoadingAnimation
            loadingText={
              nameOfSite === 0 ? loadingProfileStr : loadingTextStr
            }
          />
        ) : (
          <div id="container" className="container">
            <main id="home" className="show">
              {uuidCredit ? (
                <section className="userFormContainer">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      checkForUsername();
                    }}
                  >
                    <div className="inputContainer">
                      <input
                        id="search"
                        type="text"
                        placeholder={`${DISPLAY_STRING_GH} user name/URL or ${DISPLAY_STRING_SO} user number/URL`}
                        value={enteredText}
                        onChange={(e) => setEnteredText(e.target.value)}
                      />
                      <button type="submit">
                        <img src={searchIcon} alt="search" />
                      </button>
                    </div>
                    <div> 
                      
                    </div>
                  </form>
                </section>
              ) : null}
              {false ? (
                <section className="profile">
                  <div className="nameLocation">
                    <img src={profileImg} alt="profile" />
                    <div>
                      <h3>{username}</h3>
                      <p></p>
                    </div>
                  </div>

                  {/* {false ? (
                    <div className="followers">
                      <p>GITHUB FOLLOWERS</p>
                      <h4>5</h4>
                      <p>STACK OVERFLOW REPUTATION</p>
                      <h4>123</h4>
                    </div>
                  ) : null} */}
                </section>
              ) : null}









 {/* Shown to non logged in users */}
 {!uuidCredit && !githubToken && !stackoverflowToken && !result && !resultStackOverflow ? (
<section className="other-ranking">
  <div className="other-ranking-heading">
    {/* <h3>Do all developer applicants look the same?</h3> */}
    <h3>{RecruiterOrEmployerSalesTitleText()}</h3>
  </div>

  <div className="other-ranking-details">
           
  {RecruiterOrEmployerSalesText()}


{/* <p className="p-other-desc"><br/><a style={{textDecoration: "underline"}} onClick={navigateToRegister}>GET STARTED NOW FOR FREE</a><br/><br/></p> */}

<p className="p-other-desc">
  <br/>Check 5 of your applicants free - it just takes 1 minute:
</p>

                        {/* <p className="p-other-desc"><br/>THEN <a style={{textDecoration: "underline"}} href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid" target="_blank" rel="noopener noreferrer">AUTOMATE</a><br/><br/></p> */}


 </div>

                  <OtherRankingRegister navigateToRegister={navigateToRegister} show_btn_Tag={true} show_a_Tag={false} />

</section>
 ) : null}


















              {!hideGH ? (
                <section className="gitHub-ranking">

                  <div className="gitHub-ranking-heading">
                    <div className="username-title">
                      <h3>
                        {result ? (
                          <span className="titleToolTip">
                            <a
                              href={`https://github.com/${username}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>
                                {`${username}'s`}
                              </p>
                              {` ${DISPLAY_STRING_GH}`}
                              {ownRatingGH || uuidCredit ? `` : ` (profile ownership verified)`}
                              <img src={newTabIcon} alt="new-tab" />                              
                            </a>
                            <p className="tooltiptext">
                              Open {DISPLAY_STRING_GH} Profile
                            </p>
                          </span>
                        ) : (
                          <span>
                            Are you a developer? <small style={{fontWeight: "normal"}}>Check your {DISPLAY_STRING_GH} rank then stand out by sharing a free verified credential proving ownership of your {DISPLAY_STRING_GH} to LinkedIn</small>
                          </span>
                        )}
                      </h3>
                    </div>
                    {result ? (
                      <a
                        href={`https://github.com/${username}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={gitHubLogo} alt="github-logo" />
                      </a>
                    ) : (
                      <>
                        <span id="btn-authorize-gitHub" style={{order: "2"}} className="btn-login">
                          <div>
                            <LoginGitHub onCode={setToken} />
                            <p style={{textAlign: "center"}}>No signup required!<a href="https://linkedin.com/in/hinesystems" target="_blank">Example</a>(scroll down to Licenses & certifications)</p>
                          </div>
                        </span>
                      </>
                    )}
                  </div>


                  <div className="gitHub-ranking-details" style={{margin: result ? '20px 0' : '0'}}>

                    {result ? (
<>
                            {/* {
                              expectedLevelAndResGH === true && Congrats_Msg(true)
                            } */}
                            <RankingGHOrSO rankingAndOwnRatingAndGHElseSO={[result, ownRatingGH, true, expectedLevelAndResGH]} />

                            {/* <span className="tooltip">
                              <p className="tooltiptext tooltiptext_ranking" >
                                <br/>
                                Ranking is based on contributions to code repositories, repository stars (showing quality of projects), and a Techie's following
                              </p>
                            </span> */}
                            {
                              expectedLevelAndResGH === false && NotEnoughData_Msg(true)
                            }
                            {uuidCredit &&  expectedLevelAndResGH === false ? (

                              <>

                              <p className="gitHub-ranking-details-pa">A TechieRank ranking should <b>not</b> be required of all Techies and it should <b>not</b> replace your existing evaluation processes.</p>

                              <br/>

                              </>

                              ) : null}
                      
                     </>

                    ) : (
                      null
                    )}

                    </div>


                  {result ? (
                    
                    <div className="soft-skills">
                      <h2>Soft skills typically demonstrated by top ranked {DISPLAY_STRING_GH} Techies</h2>
                      <div className="iconContainer">
                        <div>
                          <img src={passionIcon} alt="passion-icon" />
                          <p>Motivated with passion for technology</p>
                        </div>
                        <div>
                          <img src={attitudeIcon} alt="info-icon" />
                          <p>Growth mindset & attitude towards learning</p>
                        </div>
                        <div>
                          <img src={communityIcon} alt="info-icon" />
                          <p>Creative & contribute to other's projects</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {result && !githubToken && !uuidCredit ? (

// TODO move this common code to a component
<div className="gitHub-ranking-heading" style={{borderTop: uuidCredit !== '' ? "" : "2px solid #2e3a59", padding: uuidCredit !== "" ? "" : "10px 20px"}}>
<div className="username-title">
<h3>
<span>
Are you a developer? <small style={{fontWeight: "normal"}}>Check your {DISPLAY_STRING_GH} rank then stand out by sharing a free verified credential proving ownership of your {DISPLAY_STRING_GH} to LinkedIn</small>
</span>
</h3>
</div>
<span id="btn-authorize-gitHub" style={{order: "2"}} className="btn-login">
<div>
<LoginGitHub onCode={setToken} />
<p style={{textAlign: "center"}}>No signup required!<a href="https://linkedin.com/in/hinesystems" target="_blank">Example</a>(scroll down to Licenses & certifications)</p>
</div>
</span>
</div>


                  ) : null}




{/* {ownRatingGH ? (
  <>
                  <OtherRankingRegister show_btn_Tag={true} navigateToRegister={navigateToRegister} show_a_Tag={false} />
  </>
 ) : null} */}





                </section>

              ) : null}






{/* Shown when viewing someone's profile or not logged in */}
{hideGH || uuidCredit || githubToken || stackoverflowToken ? null : (
<>
<section className="other-ranking">
<div className="other-ranking-heading">
  <h3>Get a gauge on any {DISPLAY_STRING_GH} developer's rank &amp; soft skills</h3>

</div>




<div className="soft-skills">
  
<div>
<p>
{DISPLAY_STRING_GH} is where {TOTAL_GITHUB_USERS} pesudo anonymous million developers contribute to open source &amp; manage their code repositories, demonstrating:
</p>
<br/>
</div>


                      <div className="iconContainer">
                        <div>
                          <img src={passionIcon} alt="passion-icon" />
                          <p>Motivation & passion for technology</p>
                        </div>
                        <div>
                          <img src={attitudeIcon} alt="info-icon" />
                          <p>Growth mindset & attitude towards learning</p>
                        </div>
                        <div>
                          <img src={communityIcon} alt="info-icon" />
                          <p>Creativity & contribution to other's projects</p>
                        </div>
                      </div>

                    </div>


  {!uuidCredit ? (
  <>
    <OtherRankingRegister show_btn_Tag={true} navigateToRegister={navigateToRegister} show_a_Tag={false} />
    </>

  ) : null}

</section>
</>

)}




























{!hideSO ? (
                <section className="stackoverflow-ranking" id='so_section' >
                  <div className="stackoverflow-ranking-heading">
                    <div className="username-title">
                      <h3>
                        {resultStackOverflow ? (
                          <span className="titleToolTip">
                            <a
                              href={`https://stackoverflow.com/users/${userid}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>
                                {`${SOusername}'s`}
                              </p>
                              {` ${DISPLAY_STRING_SO}`}
                              {ownRatingSO || uuidCredit ? `` : ` (profile ownership verified)`}
                              <img src={newTabIcon} alt="new-tab" />                            
                            </a>
                            <p className="tooltiptext">
                              Open {DISPLAY_STRING_SO} Profile
                            </p>
                          </span>
                        ) : (
                          <span>
                            Are you a developer? <small style={{fontWeight: "normal"}}>Check your {DISPLAY_STRING_SO} rank then stand out by sharing a free verified credential proving ownership of your {DISPLAY_STRING_SO} to LinkedIn</small>
                          </span>
                        )}
                      </h3>
                    </div>
                    {resultStackOverflow ? (
                      <a
                        href={`https://stackoverflow.com/users/${userid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={stackoverflowLogo} alt="SO-logo" />
                      </a>
                    ) : (
                      <>
                        <span id="btn-authorize-stackoverflow" style={{order: "2"}} className="btn-login">
                          <div>
                            <LoginStackOverflow onCode={setToken} />
                            <p style={{textAlign: "center"}}>No signup required!<a href="https://linkedin.com/in/hinesystems" target="_blank">Example</a>(scroll down to Licenses & certifications)</p>
                          </div>
                        </span>
                      </>
                    )}
                  </div>

                  <div className="stackoverflow-ranking-details" style={{margin: resultStackOverflow ? '20px 0' : '0'}}>
                    {resultStackOverflow ? (

<>

                            {/* {
                              expectedLevelAndResSO === true && Congrats_Msg(false)
                            } */}

<RankingGHOrSO rankingAndOwnRatingAndGHElseSO={[resultStackOverflow, ownRatingSO, false, expectedLevelAndResSO]} />

                            {/* <span className="tooltip">
                                <p className="tooltiptext tooltiptext_ranking">
                                  <br/>
                                  Ranking is based on reputation on {DISPLAY_STRING_SO} determined by community votes on quality questions, answers and comments
                                </p>
                              </span> */}
                            {
                              expectedLevelAndResSO === false && NotEnoughData_Msg(false)
                            }
                            {uuidCredit &&  expectedLevelAndResSO === false ? (

                              <>

                              <p className="gitHub-ranking-details-pa">A TechieRank ranking should <b>not</b> be required of all Techies and it should <b>not</b> replace your existing evaluation processes.</p>

                              <br/>

                              </>

                            ) : null}
                          </>

                    ) : (
                        null
                    )}

                  </div>

                    {resultStackOverflow ? (

                    <div className="soft-skills">
                      <h2>Soft skills typically demonstrated by top ranked {DISPLAY_STRING_SO} Techies</h2>

                      <div className="iconContainer">
                        <div>
                          <img src={trustIcon} alt="trust-icon" />
                          <p>Ability to solve issues themselves & comfort with ambiguity</p> 
                        </div>
                        <div>
                          <img src={commIcon} alt="comm-icon" />
                          <p>Good communication skills & ability to work with others</p>
                        </div>
                        <div>
                          <img src={qaIcon} alt="tech-icon" />
                          <p>Technically proficient</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {resultStackOverflow && !stackoverflowToken && !uuidCredit ? (




// TODO move this common code to a component
<div className="stackoverflow-ranking-heading"  style={{borderTop: uuidCredit !== '' ? "" : "2px solid #2e3a59", padding: uuidCredit !== "" ? "" : "10px 20px"}}>
<div className="username-title">
<h3>
<span>
Are you a developer? <small style={{fontWeight: "normal"}}>Check your {DISPLAY_STRING_SO} rank then stand out by sharing a free verified credential proving ownership of your {DISPLAY_STRING_SO} to LinkedIn</small>
</span>
</h3>
</div>
<span id="btn-authorize-stackoverflow" style={{order: "2"}} className="btn-login">
<div>
<LoginStackOverflow onCode={setToken} />
<p style={{textAlign: "center"}}>No signup required! <a href="https://linkedin.com/in/hinesystems" target="_blank">Example</a>(scroll down to Licenses & certifications)</p>
</div>
</span>
</div>

                  ) : null}


{/* {ownRatingSO ? (
  <>
                  <OtherRankingRegister show_btn_Tag={true} navigateToRegister={navigateToRegister} show_a_Tag={false} />
  </>
 ) : null} */}


                </section>
              ) : null}

              







{hideSO || uuidCredit || githubToken  || stackoverflowToken ? null : (

<section className="other-ranking">
<div className="other-ranking-heading">
  <h3>Get a gauge on any {DISPLAY_STRING_SO} developer's rank &amp; soft skills</h3>
</div>





<div className="soft-skills">





<div>
<p>
{DISPLAY_STRING_SO} is the largest pseudo anonymous online community for {TOTAL_STACKOVERFLOW_USERS} million developers to learn &amp; share​ ​their programming ​knowledge, demonstrating:
</p>
<br/>
</div>

<div className="iconContainer">
                        <div>
                          <img src={trustIcon} alt="trust-icon" />
                          <p>Ability to solve issues themselves & comfort with ambiguity</p>  
                        </div>
                        <div>
                          <img src={commIcon} alt="comm-icon" />
                          <p>Good communication skills & ability to work with others</p>
                        </div>
                        <div>
                          <img src={qaIcon} alt="tech-icon" />
                          <p>Technical proficiency</p>
                        </div>
</div>
</div>

{!uuidCredit ? (
  <>
                  <OtherRankingRegister show_btn_Tag={true} navigateToRegister={navigateToRegister} show_a_Tag={false} />
  </>
 ) : null}

                </section>

)}

















{!uuidCredit && !result && !resultStackOverflow ? null : (

<section className="other-ranking">
  <div className="other-ranking-heading" style={{display: (expectedLevelAndResGH !== null || expectedLevelAndResSO !== null) ? 'none': 'flex'}} >
    {/* <h3>Do all developer applicants look the same?</h3> */}
    <h3>{RecruiterOrEmployerSalesTitleText()}</h3>
  </div>


{uuidCredit && (result || resultStackOverflow) ? null : (

  <div className="other-ranking-details">
           
  {RecruiterOrEmployerSalesText()}

 </div>

 )}


{
  openDialog &&
      <LEVEL_DIALOG setExpectedLevelGH={setExpectedLevelGH} setExpectedLevelSO={setExpectedLevelSO} github_search={github_search} setOpenDialog={setOpenDialog} />
}



{!uuidCredit ? ( 
    <>
                  <OtherRankingRegister show_btn_Tag={true} navigateToRegister={navigateToRegister} show_a_Tag={true} />
                  </>
) : null}


</section>
 )}









{uuidCredit && (result || resultStackOverflow) ? ( 
                <section className="other-ranking">
                  <div className="other-ranking-heading">
                    <h3>Ready to automate within your ATS?</h3>
                  </div>
                  <div className="other-ranking-details">
                    <>
                      <span>
                      <p className="p-other-desc">
                    

                        Get your <a style={{textDecoration: "underline"}} href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid" target="_blank" rel="noopener noreferrer">free Chrome Extension</a> to automatically be shown the rank &amp; soft skills of
                          any {DISPLAY_STRING_GH} or {DISPLAY_STRING_SO} profile you browse to.
                          <br/><br/>It even displays the rank above developer's {DISPLAY_STRING_GH} or {DISPLAY_STRING_SO} profile links anywhere on the web
                           - for example above profile links within applicant's web based CV or your web based ATS!
                        </p>


                      </span>
                    </>
                  </div>

                  <OtherRankingRegister show_a_Tag={true} show_btn_Tag={false} />


                </section>
 ) : null}



{!uuidCredit ?

<section className="other-ranking">
<div className="other-ranking-heading">
<h3>Educational Resources</h3>

</div>
<div className="other-ranking-details">
<p className="p-other-desc">
<a href="how-to-land-that-developer-job-you-want.html" target="blank"><u>How to land that Developer Job you want</u></a>
</p>
</div>
</section>

: null}




            </main>
          </div>

          
        )}
          <div className="scroller">
            <h3>Some partner companies hiring & accepting TechieRank profiles</h3>
          </div>
          <div style={{width: "80%", height: "250px"}}>
            <iframe title="logos-scroller" src={process.env.REACT_APP_URL_SCROLLER+'techierank'} width="100%" height= "225px"></iframe>
          </div>


        <Footer />




      </div>

{ !ownRatingGH && !ownRatingSO ? null : (
<>
      {hash && !showShare ? (
        <button onClick={() => setShowShare(true)} className="shareButton">
          <p>Verified</p>
          <img src={LinkedInLogo} alt="share-icon" />
          <p>Credential</p>
        </button>
      ) : null}
      {showShare ? (
        <Share
          hash={hash}
          twitter={() => twitterShare()}
          linkedin={() => linkedInShare()}
          gitrank={result}
          sorank={resultStackOverflow}
          hideShare={() => setShowShare(false)}
          onCode={setToken}
        />
      ) : null}
</>
)}


      {showError.show ? (
        <ErrorCard
          msg={showError.msg}
          clearError={() => setShowError({ show: false, msg: "" })}
        />
      ) : null}
    </>
  );
};

export default RequestRating;
