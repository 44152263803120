import React from 'react';
import errorIcon from '../images/error.png';

const ErrorCard = ({ msg, clearError }) => {
	return (
		<div className='errorBox showErrorBox'>
			<img src={errorIcon} alt='error' />
			<p>{msg}</p>
			<p>
				<a href='https://reputationaire.com/contact' target='blank'>
					Click here to contact support
				</a>
			</p>
			<button onClick={clearError} type='button'>
				OK
			</button>
		</div>
	);
};

export default ErrorCard;
