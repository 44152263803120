import React from 'react';

import { createButton } from 'react-social-login-buttons';
import { SO_STRING } from '../../utils/serviceName';
import T from 'prop-types';
import OAuth from './OAuth/OAuth';

/* Create SVG Stack Overflow Icon */
const createSvgIcon = (Component) => ({ size }) => (
	<Component width={size} height={size} />
);

function Icon({ width, height }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			x='0px'
			y='0px'
			width='26'
			height='26'
			viewBox='0 0 172 172'
		>
			<g
				fill='none'
				fillRule='nonzero'
				stroke='none'
				strokeWidth='1'
				strokeLinecap='butt'
				strokeLinejoin='miter'
				strokeMiterlimit='10'
				strokeDasharray=''
				strokeDashoffset='0'
				fontFamily='none'
				fontWeight='none'
				fontSize='none'
				textAnchor='none'
			>
				<path d='M0,172v-172h172v172z' fill='none'></path>
				<g fill='#ffffff'>
					<path d='M135.82212,3.10096c-3.59195,0.49099 -6.07272,3.82452 -5.58173,7.44231l7.64904,52.50962c0.51683,3.59195 3.85036,6.09856 7.44231,5.58173c3.61779,-0.51683 6.09856,-4.03125 5.58173,-7.64904l-7.44231,-52.30288c-0.51683,-3.59195 -4.03125,-6.09856 -7.64904,-5.58173zM95.92308,16.95192c-0.85276,0.15505 -1.70553,0.54267 -2.48077,1.03365c-3.04928,1.98978 -3.85036,6.02103 -1.86058,9.09615l28.94231,44.44712c1.98978,3.04928 6.04688,3.85036 9.09615,1.86058c3.07512,-1.98978 3.85036,-6.02103 1.86058,-9.09615l-28.94231,-44.44712c-1.49879,-2.27404 -4.08293,-3.35937 -6.61538,-2.89423zM66.98077,44.65385c-1.67969,0.3101 -3.30769,1.39544 -4.34135,2.89423c-2.06731,2.9976 -1.34375,7.00301 1.65385,9.09615l43.62019,30.18269c2.97176,2.06731 7.02885,1.31791 9.09615,-1.65385c2.09315,-2.9976 1.34375,-7.23558 -1.65385,-9.30288l-43.41346,-29.97596c-1.49879,-1.03365 -3.28185,-1.55048 -4.96154,-1.24038zM51.0625,76.07692c-2.55829,0.28426 -4.80649,1.91226 -5.58173,4.54808c-1.03365,3.48858 1.05949,7.23558 4.54808,8.26923l50.64904,14.88462c3.48858,1.03365 7.23558,-0.85276 8.26923,-4.34135c1.03365,-3.48858 -1.05949,-7.23558 -4.54808,-8.26923l-50.64904,-14.88462c-0.8786,-0.25841 -1.83474,-0.3101 -2.6875,-0.20673zM13.23077,105.84615v46.30769c0,10.95673 8.88942,19.84615 19.84615,19.84615h79.38462c10.95673,0 19.84615,-8.88942 19.84615,-19.84615v-46.30769h-13.23077v46.30769c0,3.64363 -2.97176,6.61538 -6.61538,6.61538h-79.38462c-3.64363,0 -6.61538,-2.97176 -6.61538,-6.61538v-46.30769zM47.13462,106.67308c-3.64363,-0.36178 -6.87379,2.3774 -7.23558,5.99519c-0.36178,3.64363 2.3774,6.8738 5.99519,7.23558l52.71635,5.16827c3.64363,0.36178 6.8738,-2.35156 7.23558,-5.99519c0.36178,-3.61779 -2.3774,-6.87379 -5.99519,-7.23558zM46.30769,132.30769c-3.64363,0 -6.61538,2.97176 -6.61538,6.61538c0,3.64363 2.97176,6.61538 6.61538,6.61538h52.92308c3.64363,0 6.61538,-2.97176 6.61538,-6.61538c0,-3.64363 -2.97176,-6.61538 -6.61538,-6.61538z'></path>
				</g>
			</g>
		</svg>
	);
}

Icon.propTypes = {
	width: T.oneOfType([T.number, T.string]),
	height: T.oneOfType([T.number, T.string]),
};

const config = {
	text: 'Login with Stack Overflow',
	icon: createSvgIcon(Icon),
	iconFormat: (name) => `fa fa-${name}`,
	style: {
		background: '#ffaa00',
		fontFamily: 'Mulish',
		fontStyle: 'normal',
		fontWeight: '600',
	},
	activeStyle: { background: '#FFA500' },
};

const StackOverflowLoginButton = createButton(config);

/* create button */
const LoginStackOverflow = ({ onCode }) => {
	// TODO This needs to be the same as in relative-ratings-api-node datasources.<env>.json
	const urlHref = `https://stackoverflow.com/oauth/?client_id=${process.env.REACT_APP_STACKOF_ID}&redirect_uri=${process.env.REACT_APP_URL_RELATIVE_RATINGS_NODE}/stackoverflow-redirect`;
	return (
		<OAuth
			url={urlHref}
			type={SO_STRING}
			onCode={(code) => onCode(code)}
			title='Signing you in...'
			buttonComponent={StackOverflowLoginButton}
		/>
	);
};

export default LoginStackOverflow;
