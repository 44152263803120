import React, { useState } from "react";

const IssueCreditUuid = ({ issueCreditClicked, creditList }) => {
  const [credit, setCredit] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");

  const regex = /.+@hine\.org\.uk/
  const regex1 = /.+@reputationaire\.com/
  const email1 = "reputationaire@gmail.com"
  const users = creditList?.filter(x => regex?.test(x.email) !== true && x.email !== email1 && regex1.test(x.email) !== true)
  const percentage_users_used_at_least_one_creadit = (users?.filter(x => x?.total_credit > x?.outstanding_credit)?.length / users?.length) * 100 || 0.00
  const percentage_users_used_all_their_credits = (users?.filter(x => x?.outstanding_credit === 0 && x?.total_credit > 0)?.length / users?.length) * 100 || 0.00

  /* set credit, clientName, email, company to empty string */
  const emptyForm = () => {
    setCredit("");
    setClientName("");
    setEmail("");
    setCompany("");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    issueCreditClicked(clientName, email, company, credit).then(() => {
      emptyForm();
    });
  };

  return (
    <div className="wrapper creditContainer">
      <div className="creditContainer__stats">
        <h1>Stats</h1>
        <table>
          <thead>
            <tr>
              <th>Number of Users</th>
              <th>% of users who have used at least 1 credit</th>
              <th>% of users who have used all their credits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{users?.length}</td>
              <td>{percentage_users_used_at_least_one_creadit?.toFixed(2)}% ({(Math.round(users?.length * percentage_users_used_at_least_one_creadit / 100))})</td>
              <td>{percentage_users_used_all_their_credits?.toFixed(2)}% ({(Math.round(users?.length * percentage_users_used_all_their_credits / 100))})</td>
            </tr>
          </tbody>
        </table>
      </div>
      <form onSubmit={onSubmit} className="registerForm">
        <div className="createAccount">
          <p>Credit form:</p>
          <div className="inputContainer">
            <input
              type="number"
              placeholder="Enter Credit"
              name="credit"
              value={credit}
              onChange={(e) => {
                setCredit(e.target.value);
              }}
            />
          </div>
          <div className="inputContainer">
            <input
              type="text"
              placeholder="Enter Name"
              name="name"
              value={clientName}
              onChange={(e) => {
                setClientName(e.target.value);
              }}
            />
          </div>
          <div className="inputContainer">
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="inputContainer">
            <input
              type="text"
              placeholder="Company (optional)"
              name="company"
              value={company}
              onChange={(e) => {
                setCompany(e.target.value);
              }}
            />
          </div>
          <button type="submit">Issue credits</button>
        </div>
      </form>
    </div>
  );
};

export default IssueCreditUuid;
