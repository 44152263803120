/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPopup } from '../LoginPopup';

const OAuth = ({
	url,
	type,
	title,
	width,
	height,
	onCode,
	buttonComponent: Button,
}) => {
	const CODE_STRING = 'code';
	const STORAGE_STRING = 'storage';
	let externalWindow = null;

	useEffect(() => {
		return () => {
			if (externalWindow) {
				externalWindow.close();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Button onClick={() => createPopup(width, height, type, externalWindow, onCode, CODE_STRING, STORAGE_STRING, url, title)} />;
};

OAuth.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	url: PropTypes.string,
	title: PropTypes.string,
	onCode: PropTypes.func.isRequired,
};

OAuth.defaultProps = {
	width: 1000,
	height: 600,
	url: '',
	title: '',
};

export default OAuth;
