/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../images/logo.png";
import menu from "../../images/menu.png";
import { APP_NAME } from "../../utils/serviceName";

type NavProps = {
  isCredit: string;
  credit: number;
  showPayment: any;
  goToRegister: any;
  openDrawer: any;
  logout: any;
};
const Nav = ({
  isCredit,
  credit,
  showPayment,
  goToRegister,
  openDrawer,
  logout,
}: NavProps) => {
  return (
    <nav>
      <div className="innerContainer">
        <a href="/">
          <img src={logo} alt={`${APP_NAME}-logo`} />
        </a>
        <div>
          <button onClick={openDrawer}>
            <img src={menu} alt="menu" />
          </button>

          {/* <a
            href="https://chrome.google.com/webstore/detail/techierank-github-stack-o/hafpijmdkicjolibafkgekojggaaicid"
            className="navButton"
            //onClick={() => alert("Coming soon!")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Free Chrome Extension
          </a> */}

          {/* <a
            href="mailto:techierank-api@reputationaire.com?subject=TechieRank API access&amp;body=I'm interested in API access to TechieRank rankings..."
            className="navButton"
            //onClick={() => alert("Coming soon!")}
            target="_blank"
            rel="noopener noreferrer"
          >
            Get API access
          </a> */}

          {isCredit ? (
            <h4>
              <span onClick={showPayment} className="moreCreditsBtn">
                Buy Credits
              </span>{" "}
              <span className="divider">|</span>
              <span className={`creditLeft ${credit === 0 ? "no-credit" : ""}`}>
                {credit} credits
              </span>
              <span className="divider">|</span>
              <span className="logout" onClick={logout} >Logout</span>
            </h4>
          ) : (
            <a
              //href="#"
              className="navButton"
              onClick={() => goToRegister()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Check 5 Developer's Tech &amp; Soft Skills Free
            </a>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
